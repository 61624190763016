export const buildQueryParams = (params: any) => {
    const filteredParams: any = {};

    Object.keys(params).forEach((key) => {
        const value = params[key];
        if (value !== null && value !== undefined && !(Array.isArray(value) && value.length === 0) && value !== '') {
            filteredParams[key] = value;
        }
    });

    return filteredParams;
};
