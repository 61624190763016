import { Icon } from 'ch3-ui-lib';
import { FormattedMessage } from 'react-intl';
import {getFlag, getFormatedPhoneNumber} from '../../utilis/msisdnUtilis';
import {duration, getTimeAgo} from '../../utilis/timeUtilis';
import {useState} from "react";
import {getCallType} from "../../utilis/callsUtilis";
import {ClickToCall} from "../../components/ClickToCall/ClickToCall";
import {FlagImage} from "react-international-phone";
import {hexToEmoji} from "../../components/Emoji/emoji";
import listEmpty from "../../assets/list-empty.svg";
import { clsxMerge } from '../../utilis/classNameUtils';
import { borderStyles, selectedBgStyles, textStyles, detailsBgStyles } from './helpers';
import {useSelector} from "react-redux";
import {Call, useLazyFetchCallRecordQuery} from "../../store/features/calls/callsService";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";

interface RecentCallsProps {
    groupedCalls: { [key: string]: Call[] };
}

const RecentCallsDetails = ({ groupedCalls }: RecentCallsProps) => {

    const [selectedCallId, setSelectedCallId] = useState<number | null>(null);
    const theme = useSelector((state: any) => state.themeSwitcher.theme);

    const [fetchedRecordings, setFetchedRecordings] = useState<any>({});
    const [triggerFetch] = useLazyFetchCallRecordQuery();

    const handleCallClick = async (callId: number, recordId?: number) => {
        setSelectedCallId(callId === selectedCallId ? null : callId);

        if (recordId) {
            try {
                const { data: recordingUrl } = await triggerFetch(recordId);
                setFetchedRecordings((prevState: Record<number, string>) => ({
                    ...prevState,
                    [recordId]: recordingUrl,
                }));
            } catch (error) {
                console.error('Failed to fetch recording:', error);
            }
        }
    };

     function getCallDescription(call: Call): any {
        if (call.forwardedTo) {
            return 'Call forwarded to ' + getFormatedPhoneNumber(call.forwardedTo, true);
        }
        if (call.type === 'OUTBOUND') {
            return 'Outgoing call';
        }
        if (call.type === 'INBOUND' && !call.bridgetAt) {
            return 'Missed call';
        }
        if (call.type === 'INBOUND' && call.bridgetAt) {
            return 'Incoming call';
        }
        return 'Unknown call type';
    }

    if(!groupedCalls) return null;
    const sortedGroups = Object.keys(groupedCalls).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

    return (
        <div className={`border w-full  ` + clsxMerge(borderStyles({theme}))}>
            <p className={`text-lg font-semibold px-4 py-5 border-b ` + clsxMerge(borderStyles({theme}))} >
                <FormattedMessage
                    id="calls.recent"
                    defaultMessage="Recent Calls"
                    description="Recent calls header"
                />
            </p>
            {sortedGroups.map(date => {
                if (!groupedCalls[date].length) return null;
                return (
                    <div key={date}>
                        <h6 className="pl-4 py-5 font-semibold ">{date}</h6>
                        {groupedCalls[date].map((call: Call) => (
                            <div className={clsxMerge(textStyles({theme})) + ` cursor-pointer ${selectedCallId == call.callId && clsxMerge(selectedBgStyles({theme}))} ` } key={call.callId} onClick={() => handleCallClick(call.callId, call?.recordingId)}>

                                <div className={`flex justify-between items-center border-b ${selectedCallId == call.callId && 'border-b-transparent '}` + ' ' + clsxMerge(borderStyles({theme}))}>
                                    <div className="flex  pl-4 py-4">

                                        <button onClick={() => handleCallClick(call.callId, call?.recordingId)}>
                                            <Icon iconName={selectedCallId == call.callId ? 'remove' : 'add'} />
                                        </button>

                                    </div>
                                    <div className='flex items-center w-1/4'>
                                        {getCallType(call.type, call.bridgetAt, call.forwardedTo)}
                                        {getCallDescription(call)}
                                    </div>


                                    <div className={'flex items-center w-1/4'}>
                                        <Icon className="pr-4" iconName="schedule" />
                                        <p className="">{getTimeAgo(new Date(call.startedAt))}</p>
                                    </div>
                                    {Boolean(call.exposedMsisdn) &&
                                    <div className='pr-8 items-center flex'>
                                        <div className="pr-4">{hexToEmoji(call.exposedMsisdn.icon)} </div>
                                        <div className='w-[100px] truncate'>{call?.exposedMsisdn.label ? call?.exposedMsisdn.label : <ClickToCall noPrefix={true} msisdn={call.exposedMsisdn.msisdn}  />}</div>
                                    </div>
                        }
                                </div>
                                {selectedCallId == call.callId && getCallDetails(call, theme, fetchedRecordings)}
                            </div>
                        ))}
                    </div>
                );
            })}
            {sortedGroups.length === 0 &&
                    <div className='flex text-center  justify-center '>
                        <div className='w-[450px]  m-14'>
                            <div className='flex  justify-center w-full'>
                                <img className='m-4' src={listEmpty}/>
                            </div>
                            <h1 className='font-bold'>
                                No calls yet
                            </h1>
                            <div className='mb-6 mt-2'>
                                Start making calls and they'll show up here.
                            </div>
                        </div>
                    </div>
                }
        </div>
    );
};

const getCallDetails = (selectedCall: Call, theme: any, fetchedRecordings:any) => {


    return (
            <div className={`flex flex-col p-4 border-b  border-solid ` + clsxMerge(detailsBgStyles({theme}))} >


                <div className={`flex gap-5 justify-between py-3.5 w-full font-semibold ${selectedCall.user?.firstName && `border-b  border-dashed ` + clsxMerge(borderStyles({theme}))} max-md:flex-wrap max-md:max-w-full `}>
                    <div className="my-auto text-sm leading-5 text-zinc-600">
                        Phone number
                    </div>

                    <div className=" text-[16px] items-center flex">{Boolean(selectedCall.clientMsisdn) && <> <FlagImage className='w-4 h-4 mr-3'
                                                                                                                     iso2={getFlag(selectedCall.clientMsisdn) ?? 'us'}/> <ClickToCall noPrefix={true} msisdn={selectedCall.clientMsisdn}/></>}</div>


                </div>
                {(selectedCall.bridgetAt )&& <div
                    className={`flex gap-5 justify-between py-3.5 text-sm font-semibold leading-5 border-b border-black border-dashed max-md:flex-wrap max-md:max-w-full ` + clsxMerge(borderStyles({theme}))}>
                    <div className="text-zinc-600">Call length</div>
                    <div
                        className="text-right text-[16px]">     {duration(selectedCall.bridgetAt, selectedCall.finishedAt)}</div>
                </div>}
                { selectedCall.user?.firstName && <div
                    className={`flex gap-5 justify-between py-3.5 text-sm font-semibold leading-5 max-md:flex-wrap max-md:max-w-full ${selectedCall.recordingId ? 'border-b border-dashed ' : ''} `+ clsxMerge(borderStyles({theme}))}>
                    <div className="text-zinc-600">User</div>
                    <div
                        className="text-right text-[16px]">{selectedCall.user?.firstName + ' ' + selectedCall.user?.lastName}</div>
                </div>}
                {fetchedRecordings[selectedCall.recordingId] && <div className="mt-3.5 text-sm font-semibold leading-5 text-zinc-600 max-md:max-w-full">
                    Recordings
              <div className='mt-6'>
                        {selectedCall.recordingId && <AudioPlayer src={fetchedRecordings[selectedCall.recordingId]}/>}
                    </div>
                </div>}
            </div>

        );
}


export default RecentCallsDetails;
