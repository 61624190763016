import {useEffect, useState} from 'react';
import { Icon } from 'ch3-ui-lib';
import CallRow from './CallRow';
import {Call} from "../../store/features/calls/callsService";
import { innerTextStyles, borderStyles, boxStyles } from './helpers';
import { clsxMerge } from '../../utilis/classNameUtils';
import {selectTheme} from "../../store/features/ThemeSwitcher/themeSwitcherSlice";
import {useSelector} from "react-redux";

interface OnGoingCallsProps {
    calls: Call[];
    isMobile: boolean;
}

export default function OnGoingCalls({ calls, isMobile }: OnGoingCallsProps) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [ongoingCalls, setOngoingCalls] = useState([] as Call[]);
    const theme = useSelector(selectTheme);


    useEffect(() => {
        setOngoingCalls(calls);
    }, [calls]);

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className='mb-6 mr-8'>
            {ongoingCalls.length > 0 && (
                <div className={`flex justify-between bg-primary-50 border ` + clsxMerge(borderStyles({theme}))}>
                    <p className={`text-lg font-semibold px-4 py-5 ` + clsxMerge(innerTextStyles({theme}))}>Ongoing Calls</p>
                    <div className='px-4 flex items-center cursor-pointer font-semibold' onClick={handleExpandClick}>
                <span className={clsxMerge(innerTextStyles({theme}))}>
                    {isExpanded ? 'Collapse' : 'Expand'}
                </span>
                        <Icon className={clsxMerge(innerTextStyles({theme}))} iconName={isExpanded ? 'expand_less' : 'expand_more'}/>
                    </div>

                </div>)}
            {isExpanded && (
                <div className={`border-x border-b ` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}>
                    {ongoingCalls.map((
                        call: Call
                    )  => (
                        <CallRow key={call.callId} call={call} isMobile={isMobile}/>
                    ))}
                </div>
            )}
        </div>

    );
}
