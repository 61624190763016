import {Button, Icon} from "ch3-ui-lib";
import {useEffect, useRef, useState} from "react";
import AudioPlayer from "../../../components/AudioPlayer/AudioPlayer";
import './AudioRecording.css'
import {useParams} from "react-router-dom";
import DragAndDropUpload from "./DragAndDrop";
import {WaveFile} from "wavefile";


interface WelcomeMessageProps {
    welcomeMessageWorkingRef: any;
    uploadFile: Function;
}

const WelcomeMessage = ({uploadFile, welcomeMessageWorkingRef}: WelcomeMessageProps) => {


    const [action, setAction] = useState<string>('');





    const getContent = () => {
        switch (action) {
            case 'text-to-speech':
                return <TextToSpeech/>
            case 'recording':
                return <Recording uploadFile={uploadFile} welcomeMessageWorkingRef={welcomeMessageWorkingRef} />
            case 'upload':
                return  (
                    <DragAndDropUpload
                        uploadFile={uploadFile}
                        welcomeMessageWorkingRef={welcomeMessageWorkingRef}
                    />
                );
            default:
                return <SelectAction setAction={setAction}></SelectAction>;
        }
    }
    return (
       getContent()


    )
}

const SelectAction = ({setAction}: {setAction: Function}) => {

    return (
        <div>
            <div className='text-grey-500 pb-5 pt-2 w-[400px]'>
                Choose action
            </div>
            <div>
                {/*<div onClick={()=> setAction('text-to-speech')} className='flex items-center hover:bg-grey-50 cursor-pointer'>*/}
                {/*    <Icon className='text-primary-400'  iconName='description'/>*/}
                {/*    Text-to-speech*/}
                {/*</div>*/}

                <div onClick={()=> setAction('recording')} className='flex items-center hover:bg-grey-50 cursor-pointer mb-4'>
                    <Icon className="flex items-center justify-center text-primary-400 bg-primary-50 w-6 h-6 p-1.5 text-[14px] mr-3" iconName='mic'/>
                    Recording
                </div>
                <div onClick={()=> setAction('upload')} className='flex items-center hover:bg-grey-50 cursor-pointer'>
                    <Icon className=" text-primary-400 bg-primary-50 w-6 h-6 p-1.5 text-[14px] mr-3" iconName='upload_2'/>

                    Upload file
                </div>
            </div>
        </div>
    )
}

const TextToSpeech = () => {
    return (
        <>
            <div>
                Text message
            </div>
            <div className='flex border-b max-w-max hover:border-primary-500 focus-within:border-primary-500'>

            <textarea className='box-border border-none outline-none focus:ring-0 focus:ring-offset-0 caret-primary-500 w-[320px] h-[100px]' placeholder={'Enter a description...'}>

            </textarea>
            </div>
            <Button buttonType='primary' fit className='mt-5' label='Speak it' onClick={() => {}}></Button>
        </>

    )
}

const Recording = ({ uploadFile, welcomeMessageWorkingRef}:WelcomeMessageProps) => {
    const {id} = useParams<{ id: string }>();

    const [loading, setLoading] = useState(false);



    const [isRecording, setIsRecording] = useState(false);
    const [audioURL, setAudioURL] = useState<string | null>(null);
    const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
    const [recordingLength, setRecordingLength] = useState(0); // in seconds
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const audioChunksRef = useRef<Blob[]>([]);
    const recordingIntervalRef = useRef<number | null>(null);

    useEffect(() => {
        // Request microphone access
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
                mediaRecorderRef.current = mediaRecorder;

                mediaRecorder.ondataavailable = event => {
                    audioChunksRef.current.push(event.data);
                };

                mediaRecorder.onstop = async () => {
                    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
                    const wavBlob = await convertWebMToWav(audioBlob);
                    const audioUrl = URL.createObjectURL(wavBlob);
                    setAudioBlob(wavBlob);
                    setAudioURL(audioUrl);
                    audioChunksRef.current = [];
                    setRecordingLength(0); // Reset recording length
                    if (recordingIntervalRef.current !== null) {
                        clearInterval(recordingIntervalRef.current);
                    }
                };
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
            });
    }, []);

    const convertWebMToWav = (audioBlob: Blob): Promise<Blob> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const audioBuffer = reader.result as ArrayBuffer;
                const audioCtx = new (window.AudioContext || (window as any).webkitAudioContext)();
                audioCtx.decodeAudioData(audioBuffer, (buffer) => {
                    const wav = new WaveFile();
                    const samples = buffer.getChannelData(0);
                    const formattedSamples = new Int16Array(samples.length);
                    for (let i = 0; i < samples.length; i++) {
                        formattedSamples[i] = samples[i] * (0x7FFF);
                    }
                    wav.fromScratch(1, buffer.sampleRate, '16', formattedSamples);
                    const wavBlob = new Blob([wav.toBuffer()], { type: 'audio/wav' });
                    resolve(wavBlob);
                }, reject);
            };
            reader.readAsArrayBuffer(audioBlob);
        });
    };


    const startRecording = () => {
        if (mediaRecorderRef.current) {
            setAudioURL(null)
            mediaRecorderRef.current.start();
            setIsRecording(true);
            recordingIntervalRef.current = window.setInterval(() => {
                setRecordingLength(prevLength => prevLength + 1);
            }, 1000);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            if (recordingIntervalRef.current !== null) {
                clearInterval(recordingIntervalRef.current);
                recordingIntervalRef.current = null;
            }
        }
    };

    const formatRecordingLength = (length: number) => {
        const minutes = Math.floor(length / 60);
        const seconds = length % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
    const handleSaveMessage = async () => {
        if (audioBlob && id) {
            setLoading(true);
            const audioFile = new File([audioBlob], 'welcome_message.wav', {type: 'audio/wav'});

            const data = new FormData();
            data.append('messageFile', audioFile);
            data.append('fileName', 'welcome_message.wav');

            await uploadFile({id, data, audioURL: audioURL as string});
            setLoading(false);
            welcomeMessageWorkingRef.current.onSubmit();
        }
    }

        return (

            <div className='w-[400px]'>

                <div className='flex justify-between pt-3 pb-5'>
                   <h4 className='w-20'>{formatRecordingLength(recordingLength)}</h4>
                    <div className='w-[115px]'>
                        <svg
                            width="115"
                            height="32"
                            viewBox="0 0 115 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={isRecording ? 'recording' : ''}
                        >
                            <rect x="0.5" y="15" width="2" height="2" fill="black" />
                            <rect x="8.5" y="12" width="2" height="8" fill="black" />
                            <rect x="16.5" y="9" width="2" height="14" fill="black" />
                            <rect x="24.5" y="14" width="2" height="4" fill="black" />
                            <rect x="32.5" y="8" width="2" height="16" fill="black" />
                            <rect x="40.5" y="9" width="2" height="14" fill="black" />
                            <rect x="48.5" y="11" width="2" height="10" fill="black" />
                            <rect x="56.5" y="11" width="2" height="10" fill="black" />
                            <rect x="64.5" y="11" width="2" height="10" fill="black" />
                            <rect x="72.5" y="9" width="2" height="14" fill="black" />
                            <rect x="80.5" y="11" width="2" height="10" fill="black" />
                            <rect x="88.5" y="8" width="2" height="16" fill="black" />
                            <rect x="96.5" y="11" width="2" height="10" fill="black" />
                            <rect x="104.5" y="14" width="2" height="4" fill="black" />
                            <rect x="112.5" y="15" width="2" height="2" fill="black" />
                        </svg>
                    </div>
                    {!isRecording && <button className='flex justify-center items-center bg-error-400 rounded-full w-8 h-8'
                             onClick={startRecording} disabled={isRecording}>
                        <div className='bg-white w-4 h-4 rounded-full'></div>
                    </button>}
                    {isRecording && <button className='flex justify-center items-center bg-primary-400 rounded-full w-8 h-8'
                             onClick={stopRecording} disabled={!isRecording}>
                        <div className='bg-white w-4 h-4'></div>
                    </button>}
                </div>

                {audioURL && (

                    <div>
                      <div className='my-2 text-grey-900'>
                          Remember to listen to the welcome message
                      </div>
                      <AudioPlayer src={audioURL} />
                    </div>
                )}
                <div className='pt-8'>
                    <Button loading={loading} disabled={!audioURL} buttonType='primary' fit className='mt-5' label='Save recording' onClick={() => handleSaveMessage()}></Button>
                </div>
            </div>
        );
}

export default WelcomeMessage;
