import {cva} from "class-variance-authority";

export const sidebarStyles = cva('bg-black text-white w-[272px] h-screen flex fixed', {
    variants: {
        isMobile: {
            true: 'w-20',
        }
    }
});

export const activityStyles = cva('h-[6px] w-[6px] rounded-full mx-1', {
    variants: {
        dnd: {
            true: 'bg-error-400',
            false: 'bg-success-600',
        }
    }
} );
export const sidebarAvatarStyles = cva('flex pt-5 pb-11 justify-between ', {
    variants: {
        isMobile: {
            true: 'px-5 mx-0',
        }
    }
});
export const sidebarItemStyles = cva('px-4 py-3 cursor-pointer flex items-center  hover:bg-grey-800', {
    variants: {
        isMobile: {
            true: 'items-center justify-center',
        }
    }
});
