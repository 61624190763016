import ReactDOM from "react-dom/client";
import "./index.css";
import messages from "./i18n/en.json";
import { IntlProvider } from "react-intl";
import { store } from "./store/store";
import { Provider } from "react-redux";
import Keycloak from "keycloak-js";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import App from "./App";
import { EnvironmentProvider } from "./providers/EnvironmentProvider.tsx";
import '@radix-ui/themes/styles.css';
import * as Sentry from "@sentry/react";

if (import.meta.env.PROD) {
    Sentry.init({
      dsn: "https://00393c0fdf2c062a2c29b1cad1879a38@o77367.ingest.us.sentry.io/4507944399732736",
    });
}

function Root() {
  return (
      <>

        <EnvironmentProvider>
          {(env) => {
            const keycloakConfig = {
              url: env?.URL_SSO,
              realm: "phonehq",
              clientId: "web-app",
            };

            const keycloakClient = new Keycloak(keycloakConfig);

            return (
                <IntlProvider locale="en" messages={messages} defaultLocale="en">
                  <ReactKeycloakProvider
                      authClient={keycloakClient}
                      initOptions={{ onLoad: "login-required" }}
                  >
                    <Provider store={store}>
                      <App />
                    </Provider>
                  </ReactKeycloakProvider>
                </IntlProvider>
            );
          }}
        </EnvironmentProvider>
      </>
  );
}

ReactDOM.createRoot(document.getElementById("root")!).render(<Root />);
