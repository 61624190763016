export const hexToEmoji = (hex: string, className = '') => {

    if (isNaN(parseInt(hex, 16))) {
        return String.fromCodePoint(parseInt('1f4de', 16));
    }
    const emoji = hex.toLowerCase()
    return (

        <img className={`w-6 h-6 ${className} `} src={`https://cdn.jsdelivr.net/npm/emoji-datasource-twitter/img/twitter/64/${emoji}.png`} alt={String.fromCodePoint(parseInt(hex, 16))}/>
    );
};
