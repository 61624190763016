import {Icon} from "ch3-ui-lib";

import { getFormatedPhoneNumber, getFlag } from "./msisdnUtilis";
import {FlagImage} from "react-international-phone";
import {Call} from "../store/features/calls/callsService";


export const getCallType = (callType: string, bridgetAt:string, forwarded: any) => {
    if (forwarded) {
        return <Icon  iconName='phone_forwarded' />;
    }
    else if (!forwarded  &&!bridgetAt) {
        switch (callType) {
            case 'OUTBOUND':
                return <Icon className='text-error-400' iconName='call_made'  />;
            case 'INBOUND':
                return <Icon  className='text-error-400' iconName='call_missed' />;
            default:
                return null;
        }
    } else {
        switch (callType) {
            case 'OUTBOUND':
                return <Icon iconName='call_made' />;
            case 'INBOUND':
                return <Icon iconName='call_received' />;
            default:
                return null;
        }
    }
};


export const getCallTypeDescription = (callType: string, bridgetAt:string) => {
    if (!bridgetAt) {
        switch (callType) {
            case 'OUTBOUND':
                return 'Missed outgoing call';
            case 'INBOUND':
                return 'Missed incoming call';
            default:
                return null;
        }
    } else {
        switch (callType) {
            case 'OUTBOUND':
                return 'Outgoing call';
            case 'INBOUND':
                return 'Missed incoming call';
            default:
                return null;
        }
    }
};

export function getCallDescription(call: Call | undefined): any {
    if(!call) return  'Unknown call type';
    if (call.forwardedTo) {
        return (
            <div className="flex"><p className="mr-2">Call forwarded to </p><FlagImage className='w-4 h-4 mr-2'
            iso2={getFlag(call.forwardedTo) ?? 'us'}/><span>{getFormatedPhoneNumber(call.forwardedTo, true)}</span></div>);
    }

    if (call.type === 'OUTBOUND' && call.bridgetAt) {
        return 'Outgoing call';
    }
    if (call.type === 'OUTBOUND' && !call.bridgetAt) {
        return 'Missed outgoing call';
    }
    if (call.type === 'INBOUND' && !call.bridgetAt) {
        return 'Missed incoming call';
    }
    if (call.type === 'INBOUND' && call.bridgetAt) {
        return 'Incoming call';
    }
    return 'Unknown call type';
}
