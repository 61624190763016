import Header from "../../components/header/Header";
import {Avatar, Button, ChInput, ChTooltip} from "ch3-ui-lib";
import {Link} from "react-router-dom";
import { formatDateDayMonth, groupCallsByDate} from "../../utilis/timeUtilis";
import {useState} from "react";
import RecentCallsDetails from "./RecentCallDetails";
import {FlagImage} from "react-international-phone";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Colors} from "../../utilis/types/Types";
import { parsePhoneNumber, PhoneNumber} from 'libphonenumber-js';
import { RootState } from "../../store/store";
import { OpenDialerMargin, ClosedDialerMargin } from "../../utilis/dialerWidthUtils.ts";
import {ClickToCall} from "../../components/ClickToCall/ClickToCall";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {useGetContactByIdQuery} from "../../store/features/Contacts/contactsService";
import {Call, useFetchContactHistoryQuery} from "../../store/features/calls/callsService";
import {User} from "../../store/features/Users/userService";
import {useIntl} from "react-intl";
import { borderStyles, textStyles} from "./helpers.tsx";
import { clsxMerge } from "../../utilis/classNameUtils.ts";
import {Group} from "../../store/features/groups/groupsService";


const ContactDetails = () => {
    const { id } = useParams<{ id: string }>();
    const intl = useIntl();
    const isMobile = useMediaQuery('(max-width: 960px)');
    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);
    const theme = useSelector((state: any) =>state.themeSwitcher.theme);

    const { data: contactData, isLoading: contactLoading } = useGetContactByIdQuery(Number(id));
    const { data: callsData, isLoading: callsLoading } = useFetchContactHistoryQuery(Number(id));

    const [filter, setFilter] = useState('');

    const phoneNumbers = contactData?.phoneNumbers?.map((phoneNumber: { msisdn: number, main: boolean }) =>
        parsePhoneNumber(`+${phoneNumber.msisdn.toString()}`));

    type ContactTypeKey = keyof typeof ContactType;
    enum ContactType {
        PRIVATE = 'Private',
        PUBLIC = 'Public',
        SHARED = 'Shared'
    }


    const filteredCalls = callsData?.filter((call: Call) =>
        call.primaryContact?.lastName.toLowerCase().includes(filter.toLowerCase()) ||
        call.clientMsisdn.toString().includes(filter)
    ) || [];

    const groupedCalls = groupCallsByDate(filteredCalls, intl);


    if (contactLoading || callsLoading) {
        return <div>Loading...</div>;
    }

    return (
         contactData &&
        <>
        <div className={`w-full ` }>
            <Header header='Contact details' icon={'1F4D2'}/>
            <div className={'h-[calc(100vh-130px)] overflow-y-auto'}>
            <div className='ml-8'>
                <Link className='flex gap-4 items-center' to={'/contacts'}>
                    <Button size='small' className='pl-1' buttonType='secondary' rightIcon='arrow_back_ios'/>
                    <div>
                        Back to the contact list
                    </div>
                </Link>
            </div>
            <div className={`flex ` + `${isMobile ? ' px-4 flex-col' : isDialerHidden ? ClosedDialerMargin + ' flex-row' : OpenDialerMargin + ' flex-row'}`}>
                <div className=" w-[300px] min-w-[300px] py-8 flex-col justify-start items-center gap-6 inline-flex m-8 mb-0 pb-0 ">
                    <div className="flex-col justify-start items-center gap-3 flex">
                        <Avatar size={"xxl"} type='initials' color={contactData?.iconColor ?? Colors.auto} name={`${contactData?.firstName + " " + contactData?.lastName}`}/>
                        <div className="flex-col justify-start items-center gap-2 flex">
                            <div
                                className=" text-center  text-xl font-semibold  leading-7">{`${contactData?.firstName + " " + contactData?.lastName}`}</div>
                        </div>
                    </div>
                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                        <div className=" text-base font-semibold  leading-normal">Shared for</div>
                        <div className=" justify-start flex flex-col">
                            {Boolean(contactData?.availability === 'PRIVATE') &&
                            <div className="text-sm mb-4">This contact is private and visible only to you</div>
                            }
                            {Boolean(contactData?.availability === 'PUBLIC') &&
                            <div className="text-sm mb-4">This contact is public and visible to everyone in the company</div>
                            }
                            </div>
                        { Boolean(contactData?.availability === 'SHARED') && <div className="justify-start flex">
                            {[...contactData?.sharedForUsers ?? [], ...contactData?.sharedForGroups ?? []].map((item, index) => {

                                const isUser = (item as User).firstName !== undefined;
                                const isGroup = (item as Group).name !== undefined;

                                return (
                                    <ChTooltip
                                        key={index}
                                        alignArrow='start'
                                        trigger={
                                            <div
                                                style={{right: `${index * 20}px`}}
                                                className="p-[2px] bg-white relative"
                                            >
                                                <Avatar
                                                    size="md"
                                                    type={isUser ? 'initials' : 'icon'}
                                                    name={isUser ? `${(item as User).firstName} ${(item as User).lastName}` : (isGroup ? (item as Group).name : '')}
                                                    icon={!isUser ? 'home' : undefined}
                                                    color={item.iconColor ?? Colors.auto}
                                                />
                                            </div>
                                        }
                                        content={
                                            isUser
                                                ? `${(item as User).firstName} ${(item as User).lastName}`
                                                : isGroup
                                                    ? (item as Group).name
                                                    : 'Unknown'
                                        }
                                        theme='dark'
                                    />
                                );
                            })}
                            {
                                [...contactData?.sharedForUsers ?? [], ...contactData?.sharedForGroups ?? []].length > 8 &&

                                <div
                                    style={{right: `200px`}}
                                    className={`p-[2px] bg-white relative `}
                                >
                                    <Avatar
                                        color={Colors.auto}
                                        size="md"
                                        type='initials'
                                        name={`+ ${[...contactData?.sharedForUsers ?? [], ...contactData?.sharedForGroups ?? []].length - 8}`}

                                    />
                                </div>


                            }
                        </div>}
                        
                        <div
                            className={`border-t  pt-4 self-stretch flex-col justify-start items-start gap-0.5 flex`}>
                            <div className=" text-base font-semibold  leading-normal">Phone numbers</div>
                            {phoneNumbers?.map((phoneNumber: PhoneNumber, index: number) => (
                                <div key={index} className="px-3 py-1.5 justify-start items-center gap-2 inline-flex">
                                    <FlagImage iso2={phoneNumber.country?.toLowerCase() ?? 'us'} className='w-4 h-3'/>
                                    <ClickToCall  noPrefix={true} msisdn={Number(phoneNumber.number)} icon={true} />

                                </div>))
                            }
                        </div>

                        <div
                            className={`border-t  pt-4 self-stretch flex-col justify-start items-start gap-2 flex`}>
                            <div className=" text-base font-semibold  leading-normal">Email</div>
                            <div className="text-smormal  leading-tight">{contactData?.email ?? '---'}</div>
                        </div>

                        <div
                            className={`border-t  pt-4 self-stretch flex-col justify-start items-start gap-2 flex`}>
                            <div className=" text-base font-semibold  leading-normal">Company</div>
                            <div className=" text-sm  leading-tight">{contactData?.company ?? '---'}</div>
                        </div>

                        <div
                            className={`border-t  pt-4 self-stretch flex-col justify-start items-start gap-2 flex`}>
                            <div className=" text-base font-semibold leading-normal">Contact availability
                            </div>
                            <div className=" text-sm  leading-tight">{ContactType[contactData?.availability as ContactTypeKey]}</div>
                        </div>

                        <div

                            className= {`border-t  self-stretch flex-col justify-start items-start gap-2 flex pt-4 ` + clsxMerge(borderStyles({theme}))}>
                            <div className={`text-sm  leading-tight ` + clsxMerge(textStyles({theme}))}>Created by: {' ' + contactData?.owner.firstName + ' ' + contactData?.owner.lastName }, {' ' +formatDateDayMonth(new Date(contactData?.createdAt))}

                            </div>
                        </div>
                    </div>

                    <Link to={`../contacts/${id}`}> <Button  className='mb-4' onClick={() => console.log('test')} size={'small'} leftIcon={'edit'}
                                                        buttonType='primary' label={'Edit Contact'}/></Link>

                </div>
                <div className=" h-[calc(100vh-190px)] overflow-y-auto py-8 flex-col justify-start items-center gap-6 inline-flex p-4 m-4 w-full ">
                    {/*<div className='w-full flex justify-start gap-2'>*/}
                    {/*    <Button size={'small'} leftIcon='filter_list' buttonType={'secondary'} label={'Show all'}/>*/}
                    {/*    <Button size={'small'} leftIcon='filter_list' buttonType={'secondary'} label={'Calls'}/>*/}
                    {/*    /!*<Button leftIcon='filter_list' buttonType={'secondary'} label={'Messages'}/>*!/*/}
                    {/*</div>*/}
                    <div className='w-full flex justify-start'>
                        <ChInput className='min-w-[375px]'  prefixIcon={'search'}
                                 callback={(value: string) => setFilter(value)} placeholder='Search'/>

                    </div>
                    {groupedCalls ? <RecentCallsDetails groupedCalls={groupedCalls}/> : <div>loading...</div>}
                </div>
            </div>
            </div>

        </div>
        </>
    );
}


export default ContactDetails;
