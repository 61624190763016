import {useCallService} from "../../services/callService";
import {getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";
import {Icon} from "ch3-ui-lib";

interface ClickToCallProps {
    label?: string | undefined,
    msisdn: number
    noPrefix?: boolean
    className?: string
    icon?: boolean
    internal?: boolean
}

export const ClickToCall = ({ label, msisdn,noPrefix,icon,className, internal = false }: ClickToCallProps) => {
    const { makeCall } = useCallService();


    const handleClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        makeCall({
            msisdn: msisdn.toString(),
            fromName: label,
            isInternal: internal,
        });
    };

    return (
        <div  onClick={(e:any)=> handleClick(e) } className={`flex items-center hover:text-primary-500 cursor-pointer ${className}`}>{label ? label : getFormatedPhoneNumber(msisdn, noPrefix)}
            <div>
                {icon && <Icon className=' hover:text-primary-500 cursor-pointer' iconName={'call'} size='sm'/>}
            </div> </div>
    )
}
