import {Avatar, ChDropdown, ChModal, Icon, Switch, ChTooltip} from "ch3-ui-lib";
import {activityStyles, sidebarAvatarStyles, sidebarItemStyles, sidebarStyles} from "./helpers";
import {clsxMerge} from "../../utilis/classNameUtils";
import {Link} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";
import {Colors} from "../../utilis/types/Types";
import {useEffect, useRef, useState} from "react";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {SetStatusModal} from "./setStatusModal";
import {hexToEmoji} from "../Emoji/emoji";
import {convertFromISOFormat} from "../../utilis/timeUtilis";
import {useNavigate} from "react-router-dom";
import {sipUserAgent} from "../Dialer/services/sipService";
import {useFetchCurrentUserQuery, User, useToggleDndMutation} from "../../store/features/Users/userService";
import {useGetStatusesQuery} from "../../store/features/Team/teamService";
import {useSelector} from "react-redux";
import {selectUserId} from "../../store/features/Auth/authSlice";

export interface ChSidebarItem {
    icon?: any;
    customIcon?: any;
    label: string;
    link: string;
    badge?: string;
    subItems?: ChSidebarItem[];


}

export interface SectionItems {
    title?: string;
    items: ChSidebarItem[]
}

export interface ChSidebarProps {
    sections: SectionItems[];
    bottomMenu?: ChSidebarItem[];
    logo?: JSX.Element;
}

export default function ChSidebar({sections, logo, bottomMenu}: ChSidebarProps) {
    const [isAdminMenu, setIsAdminMenu] = useState(false);

    const role = localStorage.getItem('role');

    const adminSections: SectionItems[] = [
        {
            title: "Settings",
            items: [
                {label: "Integrations", link: "/integrations", icon: "integration_instructions"},
            ],
        },
    ];


    const navigate = useNavigate();
    const [useToggleDnd] = useToggleDndMutation();

    const userId = useSelector(selectUserId)

    const {data: user = {} as User} = useFetchCurrentUserQuery(userId);

    const {data: status = [], refetch} = useGetStatusesQuery(
        {users: user?.userId ? [user.userId] : []},
        {skip: !user?.userId}
    );
    const currentUserStatus = status[0]


    const {dateString, timeString} = convertFromISOFormat(currentUserStatus?.customUserStatus?.dueDate || '');

    const expires = dateString ? `${dateString}, ${timeString}` : timeString;

    const isMobile = useMediaQuery('(max-width: 960px)');

    const [isExpired, setIsExpired] = useState(true);
    console.log(isExpired);

    const checkStatusExpiration = () => {
        if (!currentUserStatus) return;
        if (currentUserStatus?.customUserStatus?.dueDate === null) {
            refetch();
            setIsExpired(false);
        } else if (currentUserStatus?.customUserStatus?.dueDate) {
            const now = new Date();
            const dueDate = new Date(currentUserStatus?.customUserStatus.dueDate);
            setIsExpired(now > dueDate);
        } else {
            refetch();
            setIsExpired(true);
        }
    };

    useEffect(() => {
        checkStatusExpiration();

        const interval = setInterval(() => {
            checkStatusExpiration();
        }, 60 * 1000);

        return () => clearInterval(interval);
    }, [currentUserStatus?.customUserStatus?.dueDate]);


    const getStatusLabel = () => {
        return currentUserStatus?.customUserStatus?.name || 'Set status';
    };

    const statusTrigger = <div id={'status'} className='flex items-center'>
        <ChTooltip theme={'dark'} alignArrow='start' content={getStatusLabel()}
                   trigger={<div className="w-[100px] truncate">{getStatusLabel()}</div>}></ChTooltip>
        {user?.status?.customUserStatus?.name &&
            <span className='text-grey-500 ml-6'>Expires {expires}</span>}</div>

    const logOutTrigger = <div id={'logout'} className='flex items-center'>Log out</div>
    const {keycloak} = useKeycloak();

    const dropdownGroup = () => {
        return ([{
            callback: (item: any) => {

                if (item.props?.id === 'status') {
                    document.getElementById('status')?.click()
                    return;
                }
                if (item.props?.id === 'logout') {
                    document.getElementById('logout')?.click()
                    return;
                }
                if (item === 'profile') {
                    navigate('/profile')
                    return;
                }
                if (item === 'admin') {
                    console.log('admin')
                    setIsAdminMenu(!isAdminMenu);
                    return;
                }

            },
            items: [
                {
                    key: 'profile',
                    label: 'Profile Settings',
                    value: 'profile',
                    prefixElement: <Avatar color={user?.iconColor ?? Colors.auto}
                                           name={user?.firstName + ' ' + user?.lastName} type='initials' size='sm'/>,
                },
                {
                    key: 'dnd',
                    prefixElement: !isExpired &&
                        <Icon size='xxs' iconName='remove' background={true} className='bg-error-400 text-white '/>,
                    value: <div onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault()
                    }} className='flex items-center  justify-between'>
                        <div className='w-[200px]'>
                            Do not disturb
                        </div>
                        <Switch alignLeft={true} size='sm'
                                checked={Boolean(currentUserStatus?.doNotDisturbUserStatus?.enabled)}
                                callback={async () => {
                                    await useToggleDnd({
                                        enabled: !currentUserStatus?.doNotDisturbUserStatus.enabled, userId
                                    })
                                    refetch();
                                }}/>
                    </div>,


                },
                {
                    key: 'status',
                    prefixElement: currentUserStatus?.customUserStatus?.icon ? hexToEmoji(currentUserStatus?.customUserStatus?.icon) : null,

                    value: statusTrigger,
                },
                ...(role === 'ADMIN' || role === 'SUPERADMIN'
                    ? [{
                        key: 'admin',
                        prefixElement: <Icon size="sm" iconName="settings" className="justify-center"/>,
                        value: 'admin',
                        label: 'Admin panel',
                    }]
                    : []),
                {
                    key: 'logout',
                    prefixElement: <Icon size='sm' iconName='logout' className=' justify-center'/>,
                    value: logOutTrigger,

                }
            ]
        }])

    }

    const setStatusRef = useRef<{ onSubmit: () => void }>(null);
    const userDropdownTrigger = () => {
        return (
            <>
                <div className='flex items-center px-6 py-3'>
                    <Avatar size='md' type={'initials'} name={user?.firstName + ' ' + user?.lastName}
                            color={user?.iconColor ?? Colors.auto}/>
                    {Boolean(!isMobile) && <div>
                        <span
                            className='text-sm px-4 cursor-pointer truncate w-[150px] block'>{user?.firstName + ' ' + user?.lastName}</span>
                    </div>}
                    {Boolean(!isMobile) && <div className='items-center flex text-xs'>
                        <span
                            className={clsxMerge(activityStyles({dnd: currentUserStatus?.doNotDisturbUserStatus?.enabled}))}></span>
                        {/*<span>{Boolean(user.dnd) ? 'DND' : 'Active'}</span>*/}
                    </div>}
                </div>


            </>
        )
    }

    const sideBarItem = (item: ChSidebarItem) => {
        return (

            <Link to={item.link}
                  className={`${item.customIcon ? 'px-6 ' : ' '}` + clsxMerge(sidebarItemStyles({isMobile}))}>
                {Boolean(item.icon) && <Icon size='sm' className='text-white pr-3' iconName={item.icon}></Icon>}
                {Boolean(item.customIcon) && (<img width="22" height="22" className=' pr-2'
                                                   src={item.customIcon}></img>)}

                {
                    Boolean(!isMobile) && (

                        <span>
                            <span className='text-grey-50'>{item.label}</span>
                            {Boolean(item.badge) && <span>{item.badge}</span>}
                        </span>
                    )
                }
            </Link>
        )
    }
    const sideBarSection = (section: SectionItems) => {
        return (
            <div>
                {
                    Boolean(!isMobile) ? (
                        <span>
                            {
                                Boolean(section.title) &&
                                <h1 className='text-grey-300 px-5 font-medium text-sm py-3'>{section.title}</h1>
                            }
                        </span>
                    ) : <div className='border-b'></div>}

                <div>
                    {section.items.map((item: ChSidebarItem) => (sideBarItem(item)))}
                </div>
            </div>
        )
    }

    return (

        <div className={clsxMerge(sidebarStyles({isMobile}))}>
            <div className='flex flex-col justify-between'>

                <div className='flex flex-col'>
                    {
                        Boolean(!isMobile && !isAdminMenu) && (
                            <div className='text-2xl px-5 font-medium pt-8 pb-5 '>
                                {Boolean(logo) && logo}
                            </div>
                        )
                    }
                    {
                        isAdminMenu && (
                            <div onClick={() => setIsAdminMenu(false)}
                                 className='text-base cursor-pointer px-5 font-medium pt-8 pb-5 flex items-center'>
                                <Icon size='sm' iconName='arrow_back_ios' className='pr-2'/>
                                <div>
                                    Back to Workspace
                                </div>
                            </div>
                        )
                    }

                    <div>{(isAdminMenu ? adminSections : sections).map((section) => sideBarSection(section))}</div>
                </div>
                {user && <div>
                    <div>
                        {Boolean(bottomMenu?.length) && (bottomMenu?.map((item) => (sideBarItem(item))))}
                    </div>
                    <div className={`flex flex-col ` + clsxMerge(sidebarAvatarStyles({isMobile}))}>
                        {Boolean(user?.status?.customUserStatus?.name) &&
                            <div className="flex bg-grey-800 px-6 py-3">
                                <div className="mr-2">{hexToEmoji(user?.status?.customUserStatus?.icon)}</div>
                                <ChTooltip theme={'dark'} alignArrow='start' content={getStatusLabel()} trigger={<div
                                    className="w-[200px] truncate text-sm">{getStatusLabel()}</div>}></ChTooltip>
                            </div>
                        }
                        <ChDropdown width={345} trigger={userDropdownTrigger()} dropdownGroup={dropdownGroup()}/>
                    </div>
                </div>}
            </div>
            <div className={'hidden'}>
                <ChModal
                    callback={(val: boolean) => {
                        console.log(val)
                    }}
                    ref={setStatusRef}
                    title={'Set a status'}
                    trigger={statusTrigger}
                    content={<SetStatusModal refetch={refetch} currentUser={user} setStatusRef={setStatusRef}/>}
                    closeButton={true}
                />
                <ChModal callback={
                    (res: boolean) => {
                        if (res) {
                            localStorage.removeItem("token");
                            localStorage.removeItem("userId");
                            localStorage.removeItem("tenant");
                            if (sipUserAgent) {
                                sipUserAgent.stop();

                            }
                            keycloak.logout()
                        }
                    }
                } title={'Log out'}
                         trigger={logOutTrigger}
                         content={<div className={'p-4 mb-4'}>Do you want to log out?</div>} secondaryLabel='Cancel'
                         primaryLabel='Confirm'/>
            </div>
            <button onClick={() => console.log(user)} className='hidden' id='status'></button>
        </div>
    )
}
