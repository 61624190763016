import { useSelector } from 'react-redux';
import {
    getAutoGainControl,
    getSelectedMicrophone,
    selectSelectedPhoneNumber, setCallFrom, setCallState
} from "../../../store/features/calls/dialerSlice";
import {useAppDispatch} from "../../../store/store";
import {CallStateEnum} from "../Dialer";
import disconnectSound from '../../../assets/sounds/disconnected.mp3';

export const useCallOptions = () => {
    const dispatch = useAppDispatch();
    const disconnectAudioRef = new Audio(disconnectSound);
    const selectedPhoneNumber = useSelector(selectSelectedPhoneNumber);
    const selectedMicrophone = useSelector(getSelectedMicrophone);
    const autoGainControl = useSelector(getAutoGainControl);

    const mediaConstraints: MediaStreamConstraints = {
        audio: {
            autoGainControl: autoGainControl,
            ...(selectedMicrophone ? { deviceId: selectedMicrophone } : {})
        },
        video: false
    };

    const eventHandlers = {
        'progress': (e: any) => {
            console.log(e, 'call is in progress');
            dispatch(setCallState(CallStateEnum.CONNECTING));
        },
        'failed': (e: any) => {
            console.log(e);
            console.log('call failed with cause: ' + e.cause);
            dispatch(setCallFrom(null));
            dispatch(setCallState(CallStateEnum.IDLE));

        },
        'ended': (e: any) => {
            console.log(e);
            console.log('call ended with cause: ' + e.cause);
            dispatch(setCallFrom(null));
            disconnectAudioRef.play();
            dispatch(setCallState(CallStateEnum.IDLE));
        },
        'confirmed': (e: any) => {
            console.log(e, 'call confirmed');
            dispatch(setCallState(CallStateEnum.IN_CALL));
        }
    };

    const options = {
        ...(selectedPhoneNumber ? { fromUserName: selectedPhoneNumber.msisdn.toString() } : {}),
        'eventHandlers': eventHandlers,
        mediaConstraints,
        pcConfig: {
            "iceServers": [
                {
                    "urls": [
                        "stun:stun.cloudflare.com:3478"
                    ]
                }
            ]
        }
    };

    return options;
};
