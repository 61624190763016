import {cva} from "class-variance-authority";

export const borderStyles = cva(' ', {
    variants: {
        theme: {
            light: ' border-black',
            dark: ' border-white',
        }
    }
} );

export const textStyles = cva(' ', {
    variants: {
        theme: {
            light: ' text-black',
            dark: ' text-white',
        }
    }
} );

export const boxStyles = cva(' ', {
    variants: {
        theme: {
            light: ' bg-white',
            dark: ' bg-black',
        }
    }
} );

export const innerTextStyles = cva(' ', {
    variants: {
        theme: {
            light: ' text-black',
            dark: ' text-white',
        }
    }
} );

export const selectedBgStyles = cva(' ', {
    variants: {
        theme: {
            light: ' bg-grey-50 hover:bg-grey-50',
            dark: ' bg-black hover:bg-black',
        }
    }
} );

export const detailsBgStyles = cva(' ', {
    variants: {
        theme: {
            light: ' bg-gray-50',
            dark: ' bg-black',
        }
    }
} );

