import {Button, ChModal} from "ch3-ui-lib";
import { useNavigate  } from 'react-router-dom';
import {useParams} from "react-router-dom";
import {useDeleteGroupMutation} from "../../store/features/groups/groupsService";



const DeleteGroupModal = () => {
    const {id} = useParams<{ id: string }>();

    const navigate = useNavigate ();
    const[useDeleteGroup] = useDeleteGroupMutation();
    const handleRemove = async (res: boolean) => {
        if(!res) return;
        await useDeleteGroup(Number(id));
        navigate("../groups");
    }
    const DeleteGroupTrigger = <Button buttonType='destructiveSecondary' leftIcon='delete' label='Delete this group'/>;
    return (
        <div >
            <ChModal  primaryLabel='Delete' secondaryLabel={'Cancel'} callback={handleRemove} icon={'destructive'} modalType='destructive' trigger={DeleteGroupTrigger} title='Delete Group' content={<DeleteGroupContent/>}/>
        </div>
    )
}
const DeleteGroupContent = () => {


    return (
        <div>
            <div className='w-80 text-grey-500 text-sm pb-5'>
                Are you sure you want to delete this group?
                This action cannot be undone.
            </div>
        </div>
    )
}
export default DeleteGroupModal;
