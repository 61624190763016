import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';


import {getApiUrl} from "../../../config";

export interface GoogleUser {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
}

interface Google {
    response: {
        users: GoogleUser[]
    }
    integrationId: number;
}

const getHeaders = () => ({
    'Content-Type': 'application/json',
    'tenant': localStorage.getItem('tenant'),
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
});

export const startIntegrationGoogle = createAsyncThunk<Google, any, { rejectValue: {message:string} }>(
    'integrations/startIntegration',
    async (integration, thunkAPI) => {
        try {
            const response = await axios.post(`${await getApiUrl()}/api/v1/integrations/google`, {email: integration}, {
                headers: getHeaders(),
            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return thunkAPI.rejectWithValue(error.response?.data);
            } else {
                throw error;
            }
        }
    }
);

export const importGoogleContacts = createAsyncThunk<any, any, { rejectValue: string }>(
    'integrations/importGoogleContacts',
    async (data, thunkAPI) => {
        try {
            const response = await axios.post(`${await getApiUrl()}/api/v1/integrations/google/${data.integration}/users`, data, {
                headers: getHeaders(),
            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return thunkAPI.rejectWithValue(error.response?.data);
            } else {
                throw error;
            }
        }
    }
);

const integrationsSlice = createSlice({
    name: 'status',
    initialState: {
        integration: {},
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(startIntegrationGoogle.fulfilled, (state, action) => {
                state.integration = action.payload;
            }
        );
    },
});


export default integrationsSlice.reducer;
