
import {useState} from "react";
import {PhoneNumberInput} from "../../../components/Dialer/components/PhoneInput";

interface ForwardCallProps {
    isValid: Function;
    type: string;
    msisdn: number | null | undefined;
}

const ForwardCall = ({isValid ,msisdn, type}: ForwardCallProps) => {
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>(msisdn ? msisdn.toString() : undefined);
    const handleChange = (val: number) => {
        if (isValid(val, type)) {
            setPhoneNumber(val.toString());
        }
    }
    return (
        <div className='mb-8'>
            <div className='text-sm text-gray-500 mt-2 mb-5'>
                All incoming calls will be forwarded to this number.
            </div>
            <PhoneNumberInput phoneNumber={phoneNumber}
                              onPhoneNumberChange={(val) => handleChange(val)}/>
        </div>

    )
}
export default ForwardCall;
