import {Button, ChModal} from "ch3-ui-lib";
import { useNavigate  } from 'react-router-dom';
import {useParams} from "react-router-dom";
import {useDeleteContactByIdMutation} from "../../store/features/Contacts/contactsService";




const DeleteContactModal = () => {
    const {id} = useParams<{ id: string }>();

    const [useDeleteContact] = useDeleteContactByIdMutation();
    const navigate = useNavigate ();

    const handleRemove = async (res: boolean) => {
        if(!res) return;
        await useDeleteContact(Number(id));
        navigate("../contacts");
    }
    const deleteContactTrigger = <Button className={'absolute left-0 m-8'} buttonType='destructiveSecondary' leftIcon='delete' label='Delete this contact'/>;
    return (
        <div >
            <ChModal  primaryLabel='Delete' secondaryLabel={'Cancel'} callback={handleRemove} icon={'destructive'} modalType='destructive' trigger={deleteContactTrigger} title='Delete Contact' content={<DeleteGroupContent/>}/>
        </div>
    )
}
const DeleteGroupContent = () => {


    return (
        <div>
            <div className='w-80 text-grey-500 text-sm pb-5'>
                Are you sure you want to delete this contact?
                This action cannot be undone.
            </div>
        </div>
    )
}
export default DeleteContactModal;
