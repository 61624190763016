import {cva} from "class-variance-authority";

export const borderStyles = cva(' ', {
    variants: {
        theme: {
            light: ' border-black',
            dark: ' border-white',
        }
    }
} );

export const textStyles = cva(' ', {
    variants: {
        theme: {
            light: ' text-black',
            dark: ' text-white',
        }
    }
} );

export const boxStyles = cva(' ', {
    variants: {
        theme: {
            light: ' bg-white',
            dark: ' bg-black',
        }
    }
} );