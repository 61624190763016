import {Button} from "ch3-ui-lib";
import {Link} from "react-router-dom";

interface IntegrationDescriptionProps {
    title: string;
    description: string;
    customizeTitle: string;
    customizeDescription: string;
    startTitle: string;
    startDescription: string;
    helpTitle: string;
    helpDescription: string;
    buttonLabel: string;
}

export const IntegrationDescription = ({
                                           title,
                                           description,
                                           customizeTitle,
                                           customizeDescription,
                                           startTitle,
                                           startDescription,
                                           helpTitle,
                                           helpDescription,
                                           buttonLabel
                                       }:IntegrationDescriptionProps) => {




    return (
        <>


                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/c10f5496d29a21d114a2b15cfc115b4b1956bf568b5672e4ef086d81faafac39?placeholderIfAbsent=true&apiKey=b80f1a000b1b49588dd2774515b82ddf"
                    alt="Google Sync Illustration"
                    className="object-contain aspect-[0.9] min-w-[240px] w-[520px] max-md:max-w-full"
                />
                <section className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                    <div className="flex flex-col w-full text-black max-md:max-w-full">
                        <div className="flex flex-col mt-6 w-full max-md:max-w-full">
                            <h2 className="text-2xl font-bold leading-none max-md:max-w-full">{title}</h2>
                            <p className="mt-4 text-base leading-6 max-md:max-w-full mb-6">{description}</p>
                            <h2 className="text-2xl font-bold leading-none max-md:max-w-full">{customizeTitle}</h2>
                            <p className="mt-4 text-base leading-6 max-md:max-w-full mb-6">{customizeDescription}</p>
                            <h2 className="text-2xl font-bold leading-none max-md:max-w-full">{startTitle}</h2>
                            <p className="mt-4 text-base leading-6 max-md:max-w-full mb-6">{startDescription}</p>
                            <h2 className="text-2xl font-bold leading-none max-md:max-w-full">{helpTitle}</h2>
                            <p className="mt-4 text-base leading-6 max-md:max-w-full mb-6">{helpDescription}</p>

                        </div>
                        <div className='flex justify-end'>
                            <Link to='connect'>
                                <Button className='my-4 mx-8 w-[200px]' buttonType='primary' label={buttonLabel}/>
                            </Link>

                        </div>

                    </div>
                </section>



        </>
    );
}
