import { createApi } from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../../baseQuery";

export enum TelcoStatus {
    ONLINE = 'ONLINE',
    TALKING = 'TALKING',
    OFFLINE = 'OFFLINE',
    UNKNOWN = 'UNKNOWN',
    DND = 'DND',
}

interface CustomUserStatus {
    userId: string;
    name: string;
    icon: string;
    dueDate: string;
}

interface TelcoUserStatus {
    status: TelcoStatus;
    lastUpdated: string;
}

interface DoNotDisturbUserStatus {
    userId: string;
    enabled: boolean;
    lastUpdatedAt: string;
}

export interface UserStatus {
    userId: number;
    firstName: string;
    lastName: string;
    customUserStatus: CustomUserStatus;
    telcoUserStatus: TelcoUserStatus;
    doNotDisturbUserStatus: DoNotDisturbUserStatus;
}





// Create an API slice
export const teamApi = createApi({
    reducerPath: 'teamApi',
   baseQuery,
    endpoints: (builder) => ({

        getStatuses: builder.query<UserStatus[], { users: number[] }>({
            query: ({ users }) => {
                if (!users || users.length === 0) return ;
                return {
                    url: '/statuses',
                    params: { users: users.join(',') },
                };
            },
            transformResponse: (response: any) => {
                return response.statuses;
            },
        }),

        changeStatus: builder.mutation<any, { data: any , userId: string | null }>({
            query: ({ data, userId }) => {
                return {
                    url: `/statuses/user/${userId}/custom`,
                    method: 'PATCH',
                    body: data,
                };
            },
        }),
    }),
});


export const {
    useGetStatusesQuery,
    useChangeStatusMutation,
} = teamApi;
