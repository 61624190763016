import {Button, Icon} from "ch3-ui-lib";
import {CallStateEnum} from "../Dialer";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../store/store";
import {setShowKeypad, toggleDialerHidden} from "../../../store/features/calls/dialerSlice";
import acceptdecline from "../../../assets/acceptdecline.svg";

interface CallControlsProps {
    callState: CallStateEnum;
    call: () => void;
    rejectCall: () => void;
    endCall: () => void;
    answerCall: () => void;
    isIncomingCall: boolean;
    removeChar: () => void;
    isInCalls: boolean;
    showKeypad: boolean;
    isReady: boolean;
}

function CallControls({ callState, call, rejectCall, endCall, answerCall, isIncomingCall, removeChar, isInCalls,showKeypad, isReady }: CallControlsProps) {
    const dispatch = useAppDispatch()

    const handleKeyboardHide = () => {
        if (callState === CallStateEnum.IN_CALL) {
            dispatch(setShowKeypad(!showKeypad))
        return;
        }
        dispatch(toggleDialerHidden());
    }

    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);

    return (
        <>
            <div className={isDialerHidden ? ' absolute bottom-6'  : ' '}>

                {callState === CallStateEnum.CONNECTING &&
                    <div className={'flex gap-4  items-start  w-[320px] ml-[129px]'}>
                    <button onClick={() => rejectCall()}>
                        <i className='flex justify-center text-[32px] items-center  w-[64px] h-[64px] cursor-pointer bg-error-400 text-white material-icons-sharp p-3 rounded-full hover:bg-error-500'>call_end</i>
                    </button>
                    </div>
                }
                {callState === CallStateEnum.IDLE && !isIncomingCall &&
                    <>
                    <div className={'flex gap-4  items-start  w-[320px] ml-[129px]'}>
                        <div className='gap-8 flex'>
                            <button  onClick={() => {
                                if(isReady) {
                                    call()
                                }

                            }}>
                                <i className={`flex   justify-center text-[32px] items-center  w-[64px] h-[64px] cursor-pointer bg-success-500 text-white material-icons-sharp bg-success-500 p-3 rounded-full hover:bg-success-600 ${!Boolean(isReady) ? ' bg-grey-300' : ''}`}>call</i>

                            </button>
                            {
                                Boolean(!isDialerHidden) &&
                                <button onClick={() => removeChar()}>
                                    <Icon className={' cursor-pointer p-0 w-[32px]'} size={'xxl'} iconName={'backspace'}></Icon>
                                </button>
                            }
                        </div>
                    </div>
                    </>
                }

                {callState === CallStateEnum.IN_CALL &&
                    <div className={'flex gap-4  items-start  w-[320px] justify-center'}>
                        <button className='' onClick={() => endCall()}>
                            <i className='flex justify-center text-[32px] items-center  w-[64px] h-[64px] cursor-pointer bg-error-400 text-white material-icons-sharp p-3 rounded-full hover:bg-error-500'>call_end</i>
                        </button>
                    </div>

                }
                {callState === CallStateEnum.IDLE && isIncomingCall && !isDialerHidden &&
                    <div className={'flex gap-4  items-start  w-[320px] justify-center'}>
                        <button className='mr-11' onClick={() => endCall()}>
                            <i className='flex justify-center text-[32px] items-center  w-[64px] h-[64px] cursor-pointer bg-error-400 text-white material-icons-sharp p-3 rounded-full hover:bg-error-500'>call_end</i>
                        </button>
                        <button className='' onClick={() => answerCall()}>
                            <i className='flex justify-center text-[32px] items-center  w-[64px] h-[64px] cursor-pointer bg-success-500 text-white material-icons-sharp bg-success-500 p-3 rounded-full hover:bg-success-600'>call</i>
                        </button>
                    </div>
                }
                {Boolean(isDialerHidden) &&
                    <div className=''>
                    <div>

                        {Boolean(isDialerHidden) && !isIncomingCall && <button className='ml-2' onClick={(e) => {
                            e.stopPropagation();
                            handleKeyboardHide()
                        }}>
                            <i className='flex justify-center text-[32px] items-center  w-[64px] h-[64px] cursor-pointer bg-success-500 text-white material-icons-sharp bg-success-500 p-3 rounded-full hover:bg-success-600'>call</i>
                        </button>}
                        {Boolean(isDialerHidden) && isIncomingCall && <div>
                            <button className={'ml-2'} onClick={(e) => {
                                e.stopPropagation();
                                handleKeyboardHide()
                            }}>

                                <img className={'h-[80px] w-[80px] cursor-pointer'} src={acceptdecline}
                                    alt="acceptdecline"/>
                            </button>
                        </div>}

                    </div>
                </div>
                }
                                {Boolean(!isDialerHidden) &&
                                    <div className='absolute bottom-6'>
                                    <div>
                                        {Boolean(!isDialerHidden &&( (!isInCalls && (callState !== CallStateEnum.IN_CALL)) || (CallStateEnum.IN_CALL === callState && showKeypad) || (CallStateEnum.CONNECTING === callState && showKeypad)) && !isIncomingCall) &&
                                            <Button onClick={() => handleKeyboardHide()} leftIcon={'arrow_forward'}
                                                    buttonType={'textSecondary'}
                                                    label={'Hide keyboard'}></Button>}
                                    </div>
                                </div>
                }
            </div>
        </>
    );
}

export default CallControls;
