import { createApi } from '@reduxjs/toolkit/query/react';
import { ColorKey } from '../../../utilis/types/Types';
import {baseQuery} from "../../baseQuery";


export interface User {
    userId: number;
    email: string;
    firstName: string;
    lastName: string;
    status: UserStatus;
    iconColor: ColorKey | null;
    createdAt: string;
}

type CustomUserStatus = {
    userId: number;
    name: string;
    icon: string;
    dueDate: string;
};

type TelcoUserStatus = {
    status: string;
    lastUpdated: string | null;
};

type DoNotDisturbUserStatus = {
    userId: number;
    enabled: boolean;
    lastUpdatedAt: string;
};

type UserStatus = {
    userId: number;
    customUserStatus: CustomUserStatus;
    telcoUserStatus: TelcoUserStatus;
    doNotDisturbUserStatus: DoNotDisturbUserStatus;
};





export const usersApi = createApi({
    reducerPath: 'usersApi',
        baseQuery,
    tagTypes: ['User'],
    endpoints: (builder) => ({
        fetchUsers: builder.query<User[], void>({
            query: () => ({
                url: '/user/filter',
            }),
        }),
        fetchCurrentUser: builder.query<User, string | null>({
            query: (userId) => {

                if (!userId) throw new Error('User ID not found');
                return {
                    url: `/user/${userId}`,

                };
            },
        }),
        createUser: builder.mutation<User, Partial<User>>({
            query: (newUser) => ({
                url: '/user',
                method: 'POST',
                body: newUser,

            }),
            invalidatesTags: [{ type: 'User', id: 'LIST' }],
        }),
        updateUser: builder.mutation<User, User>({
            query: (updatedUser) => ({
                url: `/user/${updatedUser.userId}`,
                method: 'PATCH',
                body: updatedUser,

            }),

        }),
        deleteUser: builder.mutation<number, number>({
            query: (userId) => ({
                url: `/user/${userId}`,
                method: 'DELETE',

            }),

        }),
        searchUsers: builder.query<User[], string>({
            query: (phrase) => ({
                url: '/user/search',
                method: 'POST',
                params: { phrase },

            }),

        }),
        toggleDnd: builder.mutation<any, {enabled:boolean, userId: string | null }>({
            query: ({enabled, userId}) => {
                if (!userId) throw new Error('User ID not found');
                return {
                    url: `/statuses/user/${userId}/dnd`,
                    method: 'PATCH',
                    body: { enabled },

                };
            },
        }),
    }),
});

export const {
    useFetchUsersQuery,
    useFetchCurrentUserQuery,
    useToggleDndMutation,
    useSearchUsersQuery,
} = usersApi;
