import Header from "../../components/header/Header";
import {hexToEmoji} from "../../components/Emoji/emoji";
import {FlagImage} from "react-international-phone";
import {getFlag, getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";
import {Button, ChTabs} from "ch3-ui-lib";
import {Link} from "react-router-dom";
import {PhoneNumberInfo} from "./PhoneNumberInfo";
import WorkingHours from "./WorkingHours";
import CallRules from "./CallRules";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import listEmpty from "../../assets/list-empty.svg";
import appStore from "../../assets/app-store-icon.png";
import googlePlay from "../../assets/google-play-icon.png";
import {useFetchMsisdnSettingsInfoQuery} from "../../store/features/MsisdnSettings/msisdnSettingsService";
import {useParams} from 'react-router-dom';
const tabs = [
    {
        id: '1',
        label: 'Information',
        content: <PhoneNumberInfo />
    },
    {id: '2', label: 'Working hours',  content: <WorkingHours />},
    {id: '3', label: 'Call rules', content: <CallRules />}];
const PhoneNumberSettings = () => {

    const {id} = useParams<{ id: string }>();
    const isMobile = useMediaQuery('(max-width: 960px)');
    const { data: msisdn } = useFetchMsisdnSettingsInfoQuery(id as string);
    const headerItems = (
        <div className='flex justify-between m-8 text-semibold text-xl items-center '>
            <div className='flex items-center  gap-3'>
                { hexToEmoji(msisdn?.msisdn?.icon || '1F4F1')}
                {msisdn?.msisdn?.msisdn && <FlagImage iso2={getFlag(msisdn?.msisdn?.msisdn)} size={16} />}
                {msisdn?.msisdn?.msisdn && getFormatedPhoneNumber(msisdn?.msisdn?.msisdn, true)}
            </div>
            <div>

            </div>
        </div>
    )
    return (
        <div className='w-full'>

            <Header header='Phone numbers' icon={'1F4F1'}/>
            <div className='flex items-center gap-4 mx-8'>
                <Link to={'/phoneNumbers'}>
                    <Button className='pl-1' size={'small'} buttonType='secondary' rightIcon='arrow_back_ios'></Button>

                </Link>
                <div>
                    Back to the phone numbers
                </div>
            </div>

            <div>

            </div>
            {Boolean(!isMobile) && 
                        <div className=' my-5'>
                        <ChTabs headerItems={headerItems} activeTabId={tabs[0].id} className={`flex justify-end h-16 ${isMobile ? ' flex-wrap flex-col' : ' ml-[52px] mr-[384px] no-wrap flex-row'}`} tabs={tabs}/>
        
                    </div>
            }
            {Boolean(isMobile) && 

            <div className='flex text-center  justify-center border border-black m-8'>
                <div className='w-[450px]  m-14'>
                    <div className='flex  justify-center w-full'>
                        <img className='m-4' src={listEmpty}/>
                    </div>

                    <h5 className='mb-6 mt-2'>
                    Please visit our Mobile App in order to access Phone Number Settings
                    </h5>
                    <div className="flex flex-wrap justify-center items-center">
                        <a href="https://apps.apple.com/us/app/phonehq/id6503608602"><img className='m-4' width="138" src={appStore}/></a>
                        <a href="https://play.google.com/store/apps/details?id=com.phonehq"><img className='m-4' width="138" src={googlePlay}/></a>
                    </div>

                </div>

            </div>
            }
        </div>
            )
}
export default PhoneNumberSettings;
