import React, { useState, useCallback } from 'react';

import {Button, Icon} from "ch3-ui-lib";
import ProgressBar from './ProgressBar';
import mp3Icon from './../../../assets/mp3.svg';
import AudioPlayer from "../../../components/AudioPlayer/AudioPlayer";
import {useParams} from "react-router-dom";

interface DragAndDropUploadProps {
    uploadFile: Function;
    welcomeMessageWorkingRef: any;
}

const DragAndDropUpload = ({ uploadFile, welcomeMessageWorkingRef}:DragAndDropUploadProps) => {

    const {id} = useParams<{ id: string }>();
    const [file, setFile] = useState<File | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [progress, setProgress] = useState(0);



    const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
    }, []);

    const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
        const droppedFiles = event.dataTransfer.files;
        if (droppedFiles && droppedFiles.length) {
            const droppedFile = droppedFiles[0];
            if (droppedFile.type === 'audio/wav' || droppedFile.type === 'audio/mpeg') {
                setFile(droppedFile);
                startUploadFile(droppedFile);
            } else {
                alert('Only .mp3 and .wav files are accepted.');
            }
        }
    }, []);

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files;
        if (selectedFiles && selectedFiles.length) {
            const selectedFile = selectedFiles[0];
            if (selectedFile.type === 'audio/wav' || selectedFile.type === 'audio/mpeg') {
                setFile(selectedFile);
                startUploadFile(selectedFile);
            } else {
                alert('Only .mp3 and .wav files are accepted.');
            }
        }
    };

    const startUploadFile = async (file: File) => {
        setProgress(0);
        const data = new FormData();
        data.append('messageFile', file);
        data.append('fileName', file.name);
        const audioURL = URL.createObjectURL(file);

        const fakeProgress = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(fakeProgress);
                    return 100;
                }
                return prev + 10;
            });
        }, 200);

        try {
            await uploadFile({ id, data, audioURL: audioURL as string });

        } catch (error) {
            clearInterval(fakeProgress);
            alert('Upload failed!');
        }
    };

    return (
        <div>
            {!file &&
                <label className='w-[400px]' htmlFor="file-upload">
            <div
                className={'border border-dashed border-grey-400 rounded-md p-4 text-center cursor-pointer w-[352px] p-6 ' + (isDragging ? 'border-solid border-primary-500' : '')}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >

                    <div>
                        <div className='flex justify-center pb-3'>
                            <Icon iconType='secondary' size='lg' iconName='upload_2'/>
                        </div>
                        <div><span className='text-primary-400 text-sm'>Click to upload </span> or drag and drop</div>
                        <p className='text-grey-400 text-sm'>MP3, wav | 16MB max.</p>
                    </div>
            </div>

            <input
                id="file-upload"
                type="file"
                accept=".mp3,audio/*,audio/mpeg,audio/wav"
                onChange={handleFileInputChange}
                className="hidden"
            />
        </label>}
            {file && <div
                className="border border-dashed border-grey-400 rounded-md p-4  cursor-pointer  w-[400px] p-6 ">
                {progress < 100 &&

                    <div className='flex items-center gap-3'>
                        <img src={mp3Icon} alt='uploading' className='w-8 h-8 mr-2'/>
                        <div  className='w-full'>
                            <div className='mb-[6px]'>Uploading file</div>

                            <ProgressBar progress={progress}/>
                            <p className=' mt-[6px] text-sm text-grey-600 text-start'>{file.name} | {progress}% completed</p>
                        </div>

                    </div>
                }
                {progress == 100 &&
                    <div className='flex justify-between'>
                        <div className={'flex gap-3'}>
                            <Icon iconName='check_circle' iconType='success' size='md'/>
                            <div>
                                <div>Uploading file</div>
                                <div>
                                    <p className='text-sm text-grey-600 text-start'>{file.name}</p>
                                </div>
                            </div>
                        </div>
                        <Button leftIcon='delete' size='small' buttonType='textSecondary' label='Clear' onClick={() =>
                            setFile(null)
                        }/>
                    </div>


                }


            </div>}
            {progress === 100 && file &&
                <div className='pt-5'>
                    <p className='pb-2'>
                        Remember to listen to the welcome message
                    </p>
                    <div onClick={event => event.stopPropagation()}>
                        <AudioPlayer src={file ? URL.createObjectURL(file) : ''}/>
                    </div>

                </div>
            }
            {
                file && <Button onClick={()=> welcomeMessageWorkingRef.current.onSubmit()} fit disabled={progress < 100} buttonType='primary' label='Save'
                 className='mt-4'/>
            }
         </div>
    );
};

export default DragAndDropUpload;
