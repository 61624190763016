import { createSlice } from '@reduxjs/toolkit';

interface ThemeSlice {
    theme: 'light' | 'dark';
}

const initialState: ThemeSlice = {
    theme: 'light',
};

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setGlobalTheme(state, action) {
            localStorage.setItem('theme', action.payload);
            state.theme = action.payload;
        }
    },
});

export const { setGlobalTheme } = themeSlice.actions;
export const selectTheme = (state: any) => state.themeSwitcher.theme;
export default themeSlice.reducer;
