import {cva} from "class-variance-authority";

export const borderStyles = cva(' ', {
    variants: {
        theme: {
            light: ' border-black bg-white',
            dark: ' border-white bg-black',
        }
    }
} );
