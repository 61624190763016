import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getApiUrl} from "../config";
import {RootState} from "./store";

export const baseQuery = async (args:any, api:any, extraOptions:any) => {
    const baseUrl = await getApiUrl() as string;

    const state: RootState = api.getState();
    const token = state.auth?.token;
    const tenant = state.auth?.tenant;



    const baseQuery = fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers) => {
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            } else {
                console.error('Token is undefined');
            }
            if (tenant) {
                headers.set('tenant', tenant);
            } else {
                console.error('Tenant is undefined');
            }
            return headers;
        },

    });

    return baseQuery(args, api, extraOptions);
};
