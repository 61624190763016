import {getApiUrl} from "../../config";
import {useEffect} from "react";

const RecordingDownload = () => {

    useEffect(() => {
        const downloadRecording = () => {
            const currentUrl = window.location.href;
            const urlParams = new URL(currentUrl);
            const recordingId = urlParams.pathname.split('/').pop(); // Extracts 'baiyqru' from the URL
            const hsParam = urlParams.searchParams.get('hs'); // Extracts the 'hs' parameter value

            if (recordingId && hsParam) {
                getApiUrl().then((apiUrl) => {
                    const downloadUrl = `${apiUrl}/api/v1/recordings/${recordingId}?hs=${hsParam}`;


                    const anchor = document.createElement("a");
                    anchor.href = downloadUrl;
                    anchor.download = "";
                    anchor.click();


                    anchor.remove();
                });
            }
        };

        downloadRecording();
    }, []);

    return (
        <div>
            <p>Downloading recording...</p>
        </div>
    );
};

export default RecordingDownload;
