import { createApi } from '@reduxjs/toolkit/query/react';

import {baseQuery} from "../../baseQuery";
import {ColorKey} from "../../../utilis/types/Types";
export type CallState = keyof typeof CallStateEnum
export interface Call {
    callId: number;
    primaryContact: {
        contactId: number;
        firstName: string;
        lastName: string;
        iconColor: ColorKey;
        availability: string;
    };
    duplicatedContacts: {
        contactId: number;
        firstName: string;
        lastName: string;
        availability: string;
    }[];
    type: CallState;
    clientMsisdn: number;
    internalCallee: any
    exposedMsisdn: {
        msisdn: number;
        label: string;
        type: string;
        icon: string;
    };
    startedAt: string;
    bridgetAt: string;
    finishedAt: string;
    user: {
        userId: number;
        firstName: string;
        lastName: string;
    },
    forwardedTo: number;
    forwardedAt: string;
    recordingId: number;
}

interface CallList {
    calls: Call[];
    totalCount: number;
}

export enum CallStateEnum {
    INBOUND = 'INBOUND',
    OUTBOUND = 'OUTBOUND',
}


export const callsApi = createApi({
    reducerPath: 'callsApi',
    baseQuery,
    endpoints: (builder) => ({
        fetchHistoryList: builder.query<CallList, any>({
            query: ({ from, to, contacts, users, groups, phoneNumbers, limit, userId }) => ({
                url: `/call/list/${userId}`,
                params: { from, to, contacts, users, groups, phoneNumbers, limit },
            }),
            transformResponse: (response: Call[], meta) => {
                const totalCount = meta?.response?.headers.get('x-total-count');
                return {
                    calls: response || [],
                    totalCount: totalCount ? parseInt(totalCount, 10) : 0,
                };
            },
        }),
        fetchContactHistory: builder.query<Call[], number>({
            query: (contactId) => `/call/contact/list/${contactId}`,
        }),
        fetchCall: builder.query<Call, number>({
            query: (callId) => `/call/${callId}`,
        }),
        fetchCallRecord: builder.query<string, number>({
            query: (recordingId) => ({
                url: `/api/v1/recordings/${recordingId}`,
                responseHandler: (response: any) => response.blob(), // Handles binary data like files.
            }),
            transformResponse: (data: Blob) => {
                const audioBlob = new Blob([data], { type: 'audio/mpeg' }); // Adjust MIME type as needed
                return URL.createObjectURL(audioBlob);
            },
        }),
        fetchOngoingCall: builder.query<Call[], string | null>({
            query: (userId) => ({
                url: `/call/current/${userId}`,
                params: { callState: 'TALKING' },
            }),
        }),
        fetchInboundQueueCall: builder.query<Call[], string | null>({
            query: (userId) => ({
                url: `/call/current/${userId}`,
                params: { callState: 'STARTED' },
            }),
            transformResponse: (response: Call[]) =>
                response.filter((call) => call.type === CallStateEnum.INBOUND),
        }),
    }),
});

export const {
    useFetchHistoryListQuery,
    useFetchContactHistoryQuery,
    useFetchCallQuery,
    useFetchCallRecordQuery,
    useFetchOngoingCallQuery,
    useFetchInboundQueueCallQuery,
    useLazyFetchCallRecordQuery,
} = callsApi;
