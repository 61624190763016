import { Avatar, Button, ChColumn, ChDropdown, ChInput, ChTooltip, Loader } from "ch3-ui-lib";
import { FormattedMessage, useIntl } from "react-intl";
import { useCallback, useEffect, useState } from "react";
import Header from "../../components/header/Header";
import { Link } from "react-router-dom";
import { ChDropdownGroup, Colors } from "../../utilis/types/Types";
import { hexToEmoji } from "../../components/Emoji/emoji";

import noGroupsIcon from '../../assets/empty.svg';
import listEmpty from '../../assets/list-empty.svg';
import { debounce } from "lodash";
import { getFormatedPhoneNumber } from "../../utilis/msisdnUtilis";
import {ClosedDialerMargin, OpenDialerMargin} from "../../utilis/dialerWidthUtils.ts";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {FetchGroupsResponse, useFetchGroupsQuery} from "../../store/features/groups/groupsService";
import {buildQueryParams} from "../../utilis/buildQueryParams";
import {Msisdn, MsisdnSelectItem, useGetUserMsisdnsQuery} from "../../store/features/Msisdns/msisdnService";
import { borderStyles, boxStyles } from "./helpers.tsx";
import { clsxMerge } from "../../utilis/classNameUtils.ts";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {selectUserId} from "../../store/features/Auth/authSlice";


export default function GroupList() {
    const [loading, setLoading] = useState(true);
    const isMobile = useMediaQuery('(max-width: 960px)');

    const theme = useSelector((state:any) => state.themeSwitcher.theme);
    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);
    const userId = useSelector(selectUserId);

    const [phrase, setPhrase] = useState<string>('');
    const [phonePhrase, setPhonePhrase] = useState<string>('');
    const [phoneNumbersFilter, setPhoneNumbersFilter] = useState<MsisdnSelectItem[]>([]);
    const [sort, setSort] = useState<any>({});
    const [groupsQuery, setGroupsQuery] = useState<any>({});

    const intl = useIntl();
    const name = intl.formatMessage({ id: 'groups.name' });
    const phoneNumber = intl.formatMessage({ id: 'groups.users' });
    const sharedFor = intl.formatMessage({ id: 'groups.phone-numbers' });

    const msisdns = phoneNumbersFilter.map((phoneNumber: MsisdnSelectItem) => phoneNumber.msisdn);
    const order = sort?.order;
    const sortBy = sort?.property;

    const getGroupsQuery = buildQueryParams({ phrase, msisdns, order, sortBy, ...groupsQuery, userId});

    const { data: groupsData, isLoading } = useFetchGroupsQuery(getGroupsQuery);
    const { data: userMsisdns } = useGetUserMsisdnsQuery({phrase:phonePhrase, userId} );

    const filterButton = <Button size={'small'} className='mb-6' label='Phone numbers' leftIcon='filter_list' buttonType='secondary'/>;

    const phoneNumbers = userMsisdns ? userMsisdns?.data.map((msisdn:any) => (
        {
            label:<div className='flex'> <div className='mr-2'>{hexToEmoji(msisdn.icon)}</div> { msisdn.label }</div>,
            value: msisdn,
            key: msisdn.msisdnId})) : [];

    const totalGroups = groupsData?.totalCount ?? 0;
    const groups = groupsData?.groups ?? [];

    const handleDataChange = (value: string) => {
        if (value === '') {
            setPhrase('');
            return;
        }
        handlePhraseChange(value);
    };

    const handlePhraseChange = useCallback(
        debounce((phrase: string) => {
            setPhrase(phrase);
        }, 1000), // 1 second delay
        []
    );

    const handleDataSortChange = (order: string, property: string) => {
        setSort({
            order,
            property
        });
    };

    const handlePhoneNumberSelect = (value: MsisdnSelectItem) => {
        setPhoneNumbersFilter((prev: MsisdnSelectItem[]) => {
            const exists = prev.some(item => item === value);
            if (exists) {
                return prev.filter((item) => item !== value);
            } else {
                return [...prev, value];
            }
        });
    };

    const dropdownGroup: ChDropdownGroup[] = [{
        items: phoneNumbers,
        type: 'checkbox',
        callback: (value: any) => {
            handlePhoneNumberSelect(value)
        }
    }]

    const createNewContactButton = () => (
        <Link to='/groups/details/new'>
            <Button buttonType='primary' label='Create new group' />
        </Link>
    );

    const loadMore = () => {
        const lastGroup = groupsData?.groups?.at(-1);

        setGroupsQuery({
            lastName: lastGroup?.group.name ,
            lastGroupId: lastGroup?.group.groupId
        });
    };

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    return (
        <>
        {loading &&
            <div className={`flex w-full  ${isMobile ? ' px-4' : isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>
                <Loader/>
            </div>

        }
            {!loading &&
        <div className='w-full'>

            <Header header='Groups' icon={'1F46A'} button={createNewContactButton()}/>
            <div className={`  ${isMobile ? ' px-4' : isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>
                <div className={`flex justify-between ${isMobile ? ' flex-wrap mb-8 flex-col' : ' mx-8 no-wrap mb-0 flex-row'}`}>
                    <ChDropdown trigger={filterButton} type={'search'} dropdownGroup={dropdownGroup}  onPhraseChange={(value: string) => setPhonePhrase(value)} selected={phoneNumbersFilter} ></ChDropdown>
                    <ChInput placeholder={'Search'} className={`${isMobile ? ' ' : ' min-w-[375px]'}`}  prefixIcon={'search'} callback={handleDataChange}/>
                </div>

                <div className={`h-[calc(100vh-210px)] mr-4 overflow-y-auto mb-4  ${isMobile ? ' ' : ' mr-4 ml-8'}`}>
                    <div className={clsxMerge(boxStyles({theme}))}>
                    <p className={`text-lg font-semibold px-4 py-5 border-x border-t ` + clsxMerge(borderStyles({theme}))} ><FormattedMessage

                        id='groups.list'/>
                        <span className={`border rounded-sm px-2 text-xs mx-2 py-1 ` + clsxMerge(borderStyles({theme}))}>
        {totalGroups}
        </span>

                    </p>
                    {Boolean(groups.length) && <table className={` w-full border  ${isMobile ? ' overflow-x-scroll' : ' '}` + clsxMerge(borderStyles({theme}))}>

                        <thead>
                        <tr className={`border-b ` + clsxMerge(borderStyles({theme}))} >
                            <th className='pl-4 py-3 w-full'
                            ><ChColumn propertyName='NAME' callback={handleDataSortChange} label={name}/></th>
                            <th className='min-w-[300px] '
                            ><ChColumn propertyName='MSISDN' callback={handleDataSortChange} label={phoneNumber}/></th>
                            <th className='min-w-[200px]'
                            ><ChColumn propertyName='USERS' callback={handleDataSortChange} label={sharedFor}/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {groups.map((groupData: FetchGroupsResponse, index: number) => {
                                return (
                                    <tr className={`border ` + clsxMerge(borderStyles({theme}))} key={index}>
                                        <Link to={`/groups/details/${groupData.group.groupId}`}
                                              className='no-underline block w-full'>

                                            <td className='flex gap-3 font-semibold  items-center pl-4 py-4'><Avatar
                                                size="md" type='icon' icon={'home'}
                                                color={groupData.group.iconColor}
                                            />{groupData.group.name}
                                            </td>
                                        </Link>


                                        <td>
                                            <div className=" justify-start items-center flex">
                                                {
                                                    groupData.users?.map((user, index) => (
                                                        <ChTooltip

                                                            trigger={<div  style={{right: `${index * 20}px`}}
                                                                                 className={`p-[2px] bg-white relative`}>
                                                            <Avatar key={index} size="md" type='initials'
                                                                    color={user.iconColor ?? Colors.auto}
                                                                    name={user.firstName + ' ' + user.lastName}/>
                                                        </div>} content={user.firstName + ' ' + user.lastName} theme={'dark'}></ChTooltip>

                                                    ))}
                                            </div>
                                        </td>
                                        <td className={'flex gap-3 mr-4 items-center'}>
                                            {groupData.msisdns.slice(0, 2).map((el: Msisdn) => {
                                                return <div className='flex items-center'>
                                                    <div className='text-xl mr-2 '>{hexToEmoji(el.icon)}</div>
                                                    {el.label ?? getFormatedPhoneNumber(el.msisdn)}</div>
                                            })}
                                            {
                                                groupData.msisdns.length > 2 && `+${groupData.msisdns.length - 2}`
                                            }
                                        </td>

                                    </tr>
                                )
                            }
                        )}


                        </tbody>

                    </table>}
                    {groups.length === 0 && Boolean(totalGroups) &&
                        <div className={`flex text-center  justify-center  border ` + clsxMerge(borderStyles({theme}))}>
                            <div className='w-[450px]  m-14'>
                                <div className='flex  justify-center w-full'>
                                    <img className='m-4' src={noGroupsIcon}/>
                                </div>

                                <h1 className='font-bold'>
                                    No groups found
                                </h1>
                                <div className='mb-6 mt-2'>
                                    No groups found matching your search. Please double-check your criteria or consider
                                    adding new groups to expand your network!
                                </div>
                                <div className='flex justify-center'>
                                    <Link to='/groups/details/new'>
                                        <Button shadow leftIcon='add' buttonType='primary' label='Create new group'/>
                                    </Link>

                                </div>
                            </div>

                        </div>

                    }
                    </div>
                    {!totalGroups &&
                        <div className={`flex text-center  justify-center  border ` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}>
                            <div className='w-[450px]  m-14'>
                                <div className='flex  justify-center w-full'>
                                    <img className='m-4' src={listEmpty}/>
                                </div>

                                <h1 className='font-bold'>
                                    No groups yet!
                                </h1>
                                <div className='mb-6 mt-2'>
                                    Click below to add one now.
                                </div>
                                <div className='flex justify-center'>
                                    <Link to='/groups/details/new'>
                                        <Button shadow leftIcon='add' buttonType='primary' label='Create new'/>
                                    </Link>
                                </div>
                            </div>

                        </div>

                    }
                    {(Boolean(groups.length) && groups.length != totalGroups) && <div className='flex justify-center my-4'>
                        <Button onClick={loadMore} leftIcon={'more_horiz'} size='small' buttonType='secondary' label='Load more'/>

                    </div>}
                </div>

            </div>

        </div>
        }
        </>
    );
}
