import {  useDispatch } from 'react-redux';
import {setGlobalTheme} from '../../store/features/ThemeSwitcher/themeSwitcherSlice';
import { ChDropdown } from 'ch3-ui-lib';
import { useState } from 'react';


const ThemeSwitcher = () => {
    const [selected, setSelected] = useState<any>(localStorage.getItem('theme') || 'light');

    const dispatch = useDispatch();


    const handleSelect = (value: any) => {
                setSelected([value]);
                dispatch(setGlobalTheme(value));
    }

    const dropdownGroup = () => {
        return ([{
            callback: (value: any) => {
                handleSelect(value);
            },
            items: [
                {
                    key: 'dark',
                    label: 'Dark',
                    value: 'dark',
                },
                {
                    key: 'light',
                    label: 'Light',
                    value: 'light',
                }
            ]
        }])
    }

    return (
        <ChDropdown selected={[selected]}  dropdownGroup={dropdownGroup()}  />

    );
};
export default ThemeSwitcher;

