import { IntegrationDescription } from "./IntegrationDescription";
import { useParams } from "react-router-dom";
import Header from "../../components/header/Header";
import { ClosedDialerMargin, OpenDialerMargin } from "../../utilis/dialerWidthUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import useMediaQuery from "../../utilis/screenWidthUtils";
import { Button } from "ch3-ui-lib";
import google from "../../assets/google.svg";
import { IntegrationGoogle } from "./Google/IntegrationGoogle";
import { UserList } from "./Users/UserList";
import {useNavigate} from 'react-router-dom';

type IntegrationKey = "google";

interface SyncStep {
    integrationName: string;
    title: string;
    description: string;
    customizeTitle: string;
    customizeDescription: string;
    startTitle: string;
    startDescription: string;
    helpTitle: string;
    helpDescription: string;
    buttonLabel: string;
}

const syncSteps: Record<IntegrationKey, SyncStep> = {
    google: {
        integrationName: "Google Contacts",
        title: "Connect your Google Account",
        description:
            'Click on the "Connect" button and sign in with your Google account. Grant phoneHQ permission to access your contacts, ensuring a smooth and secure sync.',
        customizeTitle: "Customize Sync Settings",
        customizeDescription:
            "Choose your preferred sync options. You can select specific contact groups or sync your entire address book, giving you full control over which contacts appear in phoneHQ.",
        startTitle: "Start Syncing",
        startDescription:
            "Once you've configured your settings, initiate the sync. Your contacts will be updated automatically, helping you maintain an organized and accessible contact list across platforms.",
        helpTitle: "Need Help?",
        helpDescription:
            "If you encounter any issues, visit our Help Center or contact support for assistance with your Google contacts sync.",
        buttonLabel: "Connect with Google",
    },
};

export const Integration = () => {
    const { id, step } = useParams<{ id?: IntegrationKey; step?: "info" | "connect" | "list" }>();
    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);
    const isMobile = useMediaQuery("(max-width: 960px)");

    const navigate = useNavigate();

    const steps: Record<IntegrationKey, Record<string, JSX.Element>> = {
        google: {
            info: <IntegrationDescription {...syncSteps["google"]} />,
            connect: <IntegrationGoogle />,
            list: <UserList />,
        },
    };

    const currentId = id ?? "google";
    const currentStep = step ?? "info";

    return (
        <div className={`w-full `}>
            <Header header="Integrations" subHeader="Manage all connected apps and integrations to streamline team operations." />
            <div className="flex gap-4 items-center mx-8"  onClick={() => navigate(-1)}>
                {currentId == 'google' && <img src={google} alt="Google Logo"/>}
                <Button size="small" className="pl-1" buttonType="secondary" rightIcon="arrow_back_ios" />
                <div className={"font-bold text-xl"}>{syncSteps[currentId].integrationName}</div>
            </div>
            <div
                className={
                    ` m-8 flex ` +
                    `${isMobile ? "px-4 flex-col" : isDialerHidden ? ClosedDialerMargin + " flex-row" : OpenDialerMargin + " flex-row"} ` +
                    " flex flex-wrap gap-8 items-start "
                }
            >
                {steps[currentId][currentStep]}
            </div>
        </div>
    );
};
