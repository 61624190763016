import React, { useState, useRef, useEffect } from 'react';

interface AudioPlayerProps {
    src: string;
    disabled?: boolean;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src }) => {
    const [hideIcon, setHideIcon] = useState(false);
    const [volumeStatus, setVolumeStatus] = useState(true);
    const [audioLength, setAudioLength] = useState<string | null>(null);
    const [audioCurrentMinutes, setAudioCurrentMinutes] = useState('00');
    const [audioCurrentSeconds, setAudioCurrentSeconds] = useState('00');
    const [volumeValueOld, setVolumeValueOld] = useState(0.5);

    const audioRef = useRef<HTMLAudioElement>(null);
    const trackLineRef = useRef<HTMLInputElement>(null);
    const volumeLineRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        resetAudio();
        const audioElement = audioRef.current;


        if (audioElement) {
            audioElement.addEventListener('loadedmetadata', handleMetadataLoaded);
        }


        return () => {
            if (audioElement) {
                audioElement.removeEventListener('loadedmetadata', handleMetadataLoaded);
            }
        };
    }, [src]);    useEffect(() => {
        resetAudio();
        const audioElement = audioRef.current;


        if (audioElement) {
            audioElement.addEventListener('loadedmetadata', handleMetadataLoaded);
        }


        return () => {
            if (audioElement) {
                audioElement.removeEventListener('loadedmetadata', handleMetadataLoaded);
            }
        };
    }, [src]);
    const handleMetadataLoaded = () => {
        if (audioRef.current) {
            setAudioLength(formatTime(audioRef.current.duration));
        }
    };
    useEffect(() => {
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
            }
        };
    }, []);

    const resetAudio = () => {
        if (audioRef.current) {
            audioRef.current.currentTime = 0;
            setAudioCurrentMinutes('00');
            setAudioCurrentSeconds('00');
            updateTimeline();
        }
    };

    const play = () => {
        if (audioRef.current) {
            if (audioRef.current.readyState < 4) return;
            audioRef.current.play();
            setHideIcon(true);
            setAudioLength(formatTime(audioRef.current.duration));
        }
    };

    const pause = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            setHideIcon(false);
        }
    };

    const rewind = () => {
        if (audioRef.current) {
            audioRef.current.currentTime = Math.max(0, audioRef.current.currentTime - 5);
            updateTimeline();
        }
    };

    const forward = () => {
        if (audioRef.current) {
            audioRef.current.currentTime = Math.min(audioRef.current.duration, audioRef.current.currentTime + 5);
            updateTimeline();
        }
    };

    const updateTimeline = () => {
        if (audioRef.current && trackLineRef.current) {
            trackLineRef.current.max = audioRef.current.duration.toString();
            trackLineRef.current.value = audioRef.current.currentTime.toString();
            setAudioCurrentMinutes(formatMinutes(audioRef.current.currentTime));
            setAudioCurrentSeconds(formatSeconds(audioRef.current.currentTime));
        }
    };

    const updateAudio = () => {
        if (audioRef.current && trackLineRef.current) {
            updateTimeline();
        }
    };

    const formatTime = (timeLength: number): string => {
        if (!timeLength) return '00:00';
        const minutes = Math.floor(timeLength / 60);
        const seconds = Math.floor(timeLength % 60);
        return `${minutes >= 10 ? minutes : '0' + minutes}:${seconds >= 10 ? seconds : '0' + seconds}`;
    };

    const formatMinutes = (timeLength: number): string => {
        if (!timeLength) return '00';
        const minutes = Math.floor(timeLength / 60);
        return minutes >= 10 ? minutes.toString() : '0' + minutes;
    };

    const formatSeconds = (timeLength: number): string => {
        if (!timeLength) return '00';
        const seconds = Math.floor(timeLength % 60);
        return seconds >= 10 ? seconds.toString() : '0' + seconds;
    };

    const endAudio = () => {
        pause();
        resetAudio();
    };

    const changeVolume = () => {
        if (volumeLineRef.current && audioRef.current) {
            setVolumeStatus(parseFloat(volumeLineRef.current.value) > 0);
            audioRef.current.volume = parseFloat(volumeLineRef.current.value);
        }
    };

    const volumeOffFunc = () => {
        if (volumeLineRef.current && audioRef.current) {
            setVolumeValueOld(audioRef.current.volume);
            audioRef.current.volume = 0;
            volumeLineRef.current.value = '0';
            setVolumeStatus(false);
        }
    };

    const volumeOnFunc = () => {
        if (volumeLineRef.current && audioRef.current) {
            audioRef.current.volume = volumeValueOld;
            volumeLineRef.current.value = volumeValueOld.toString();
            setVolumeStatus(true);
        }
    };

    const handleTimelineChange = () => {
        if (audioRef.current && trackLineRef.current) {
            audioRef.current.currentTime = parseFloat(trackLineRef.current.value);
            updateTimeline();
        }
    };
    const getAudioLink = (): string => {
        return audioRef.current?.src ? audioRef.current.src : '';
    };

    const getAudioName = (): string => {
        return audioRef.current?.src ? audioRef.current.src.split('/').pop() + '.mp3' : ''
    };

    return (
        src &&
        <div onClick={event => event.stopPropagation()} className={`player-wrapper p-3 bg-white border border-black`}>
            <div className="player-content text-gray-900">
                <div className="timeline">
                    <input
                        ref={trackLineRef}
                        type="range"
                        min="0"
                        max="100"
                        step="0.000001"
                        className="timeline-input w-full h-1 bg-black appearance-none"
                        onChange={handleTimelineChange}
                    />
                </div>
                <div className="controls-wrapper flex justify-between items-center mt-[6px]">
                    <div className="controls flex space-x-4" >
                        <i onClick={rewind} className="material-icons player-icon cursor-pointer">fast_rewind</i>
                        <i onClick={pause} className={`material-icons player-icon cursor-pointer ${!hideIcon ? 'hidden' : ''}`}>pause</i>
                        <i onClick={play} className={`material-icons player-icon cursor-pointer ${hideIcon ? 'hidden' : ''}`}>play_arrow</i>
                        <i onClick={forward} className="material-icons player-icon cursor-pointer">fast_forward</i>
                    </div>
                    <div className="time text-gray-600">
                        <span>{audioCurrentMinutes}:{audioCurrentSeconds}/{audioLength}</span>

                    </div>
                    <div className="volume flex items-center space-x-2">
                        <i onClick={volumeOffFunc} className={`material-icons player-icon cursor-pointer ${volumeStatus ? '' : 'hidden'}`}>volume_up</i>
                        <i onClick={volumeOnFunc} className={`material-icons player-icon cursor-pointer ${!volumeStatus ? '' : 'hidden'}`}>volume_off</i>
                        <input
                            ref={volumeLineRef}
                            type="range"
                            min="0"
                            max="1"
                            step="0.01"
                            className="timeline-input volume w-24 h-1 bg-black focus:bg-gradient-to-r focus:from-black focus:to-black appearance-none"
                            onChange={changeVolume}
                        />
                        <a href={getAudioLink()} download={getAudioName()} className="audio-download">
                            <i className="material-icons player-icon cursor-pointer">file_download</i>
                        </a>
                    </div>
                </div>
            </div>
            <audio ref={audioRef} src={src} onTimeUpdate={updateAudio} onEnded={endAudio} className="hidden" />
        </div>
    );
};

export default AudioPlayer;
