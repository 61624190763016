import Header from "../../components/header/Header";
import {mobileWidth, tabletWidth} from "../../utilis/screenWidthUtils";
import {ClosedDialerMargin, OpenDialerMargin} from "../../utilis/dialerWidthUtils";
import {RootState} from "../../store/store";
import {useSelector} from "react-redux";
import google from '../../assets/google.svg'
import {Button} from "ch3-ui-lib";
import {Link} from "react-router-dom";

export default function Integrations() {



    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);
    return (
        <div className={`w-full ` }>
            <Header header='Integrations' subHeader={'Manage all connected apps and integrations to streamline team operations.'} />
            <div className={'h-[calc(100vh-130px)] overflow-y-auto'}>
                <div className='ml-8'>
                    {/*<Link className='flex gap-4 items-center' to={'/contacts'}>*/}
                    {/*    <Button size='small' className='pl-1' buttonType='secondary' rightIcon='arrow_back_ios'/>*/}
                    {/*    <div>*/}
                    {/*        Back to the contact list*/}
                    {/*    </div>*/}
                    {/*</Link>*/}
                </div>
                <div
                    className={`max-h-[calc(100vh-130px)] overflow-x-hidden  overflow-y-scroll flex flex-wrap ${mobileWidth || tabletWidth ? ' px-4' : 'px-6'} ${isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>
                    <div className={'w-[317px] border-black border h-[250px]'}>
                        <img className='my-6 px-4' src={google}/>
                        <div className='border-b border-black px-4 pb-4'>
                            <div className='font-medium'>Google Contacs</div>
                            <div className={'text-sm'}>Sync your Google Workspace user with PhoneHQ</div>
                        </div>
                        <Link to={'/integrations/google'}>
                            <Button className='m-4'  buttonType='primary' label='Connect' />
                        </Link>
                    </div>



                </div>
        </div>

        </div>
    );
}
