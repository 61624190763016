import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Avatar, Button, ChDropdown, ChTabs, Datepicker, Loader} from 'ch3-ui-lib';
import {useIntl} from 'react-intl';
import {groupCallsByDate} from '../../utilis/timeUtilis';
import {RootState, useAppDispatch} from '../../store/store';
import RecentCalls from './RecentCalls';
import MissedCalls from './MissedCalls';
import Header from '../../components/header/Header';
import {
    setContactsFilter, setLimit,
    setPhoneNumbersFilter, setSelectedDates,
    setUsersAndGroupsFilter
} from "../../store/features/Filters/filtersSlice";
import {hexToEmoji} from "../../components/Emoji/emoji";
import {getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {OpenDialerMargin} from "../../utilis/dialerWidthUtils.ts";
import OnGoingCalls from "./onGoingCalls";
import InboundCallQueue from "./InboundCallQueue";
import {
    Call,
    useFetchHistoryListQuery,
    useFetchInboundQueueCallQuery,
    useFetchOngoingCallQuery
} from "../../store/features/calls/callsService";
import {Contact, ContactSelectItem, useGetContactsQuery} from "../../store/features/Contacts/contactsService";
import {
    MsisdnSelectItem,
    useGetUserMsisdnsQuery
} from "../../store/features/Msisdns/msisdnService";
import {buildQueryParams} from "../../utilis/buildQueryParams";
import {useFetchUsersQuery} from "../../store/features/Users/userService";
import {useFetchGroupsQuery} from "../../store/features/groups/groupsService";
import {selectUserId} from "../../store/features/Auth/authSlice";


export default function Calls() {
    const dispatch = useAppDispatch();
    const intl = useIntl();


    const [phonePhrase, setPhonePhrase] = useState<string>('');
    const [contactPhrase, setContactPhrase] = useState<string>('');
    const [userGroupPhrase, setUserGroupPhrase] = useState<string>('');

    const [groupedCalls, setGroupedCalls] = useState<{ [key: string]: Call[] }>({});
    const [missedCalls, setMissedCalls] = useState<{ [key: string]: Call[] }>({});

    const isMobile = useMediaQuery('(max-width: 960px)');

    const {
        phoneNumbersFilter,
        contactsFilter,
        usersAndGroupsFilter,
        selectedDates,
        limit,
    } = useSelector((state: RootState) => state.filters);


    const userId = useSelector(selectUserId);


    const callsQueryParams = buildQueryParams({
        limit,
        contacts: contactsFilter.map((contact: any) => contact.contactId),
        phoneNumbers: phoneNumbersFilter.map((item: any) => item.msisdn),
        groups: usersAndGroupsFilter.filter((item: any) => item.groupId).map((item: any) => item.groupId),
        users: usersAndGroupsFilter.filter((item: any) => item.userId).map((item: any) => item.userId),
        from: selectedDates.from,
        to: selectedDates.to,
        userId,
    });
    const contacstsQueryParams = buildQueryParams({
        userId,
        phrase: contactPhrase,
    });
    const msisdnQueryParams = buildQueryParams({
        phrase: phonePhrase,
        userId,
    });
    const userGroupQueryParams = buildQueryParams({
        phrase: userGroupPhrase,
    });
    const groupQueryParams = buildQueryParams({
        phrase: userGroupPhrase,
        userId,
    });


    const {data: usersData = [], isLoading: isLoadingUsers} = useFetchUsersQuery(userGroupQueryParams);
    const {data: groupsData, isLoading: isLoadingGroups} = useFetchGroupsQuery(groupQueryParams);
    const {
        data: ongoingCalls = [],
        isLoading: isLoadingOngoingCalls
    } = useFetchOngoingCallQuery(userId, {pollingInterval: 5000});
    const {
        data: inboundQueueCalls,
        isLoading: isLoadingInboundQueueCalls
    } = useFetchInboundQueueCallQuery(userId, {pollingInterval: 5000});
    const {data: userMsisdns, isLoading: isLoadingMsisdns} = useGetUserMsisdnsQuery(msisdnQueryParams);
    const {
        data: historyList,
        isLoading: isLoadingHistory
    } = useFetchHistoryListQuery(callsQueryParams, {pollingInterval: 15000});
    const {data: userContacts, isLoading: isLoadingContacts} = useGetContactsQuery(contacstsQueryParams);

    const recentCallsCount = historyList ? historyList.totalCount : 0;

    const isLoading = isLoadingOngoingCalls || isLoadingInboundQueueCalls || isLoadingMsisdns || isLoadingHistory || isLoadingContacts || isLoadingGroups || isLoadingUsers;


    useEffect(() => {
        if (historyList?.calls) {
            setGroupedCalls(groupCallsByDate(historyList.calls, intl) ?? {});
            setMissedCalls(
                groupCallsByDate(
                    historyList.calls.filter((call: Call) => call.type === 'INBOUND' && !call.bridgetAt),
                    intl
                ) ?? {}
            );
        }
    }, [historyList]);

    const phoneNumbersFilterButton = <Button size='small' label='Phone numbers' leftIcon='filter_list'
                                             buttonType='secondary'/>;
    const contactsFilterButton = <Button size='small' label='Contacts' leftIcon='filter_list' buttonType='secondary'/>;
    const usersAndGroupsFilterButton = <Button size='small' label='Users and groups' leftIcon='filter_list'
                                               buttonType='secondary'/>;

    const phoneNumbers = userMsisdns?.data?.map((msisdn: any) => (
        {
            label: <div className='flex'>
                <div className='mr-2'>{hexToEmoji(msisdn.icon)}</div>
                {msisdn.label}</div>,
            value: msisdn,
            key: msisdn.msisdnId
        }));

    const contacts = userContacts?.contacts ? userContacts?.contacts?.concat(contactsFilter)
        .reduce<{ map: Map<number, boolean>; result: any }>((acc: any, contact: any) => {
            if (!acc.map.has(contact.contactId)) {
                acc.map.set(contact.contactId, true);
                acc.result.push({
                    key: contact.contactId,
                    label: contact.firstName + ' ' + contact.lastName,
                    description: getFormatedPhoneNumber(contact.phoneNumber),
                    hideDescription: true,
                    value: contact,
                    prefixElement: <div className='mx-2'>
                        <Avatar size="xs" type='initials' color={contact.iconColor ?? 'auto'}
                                name={contact.firstName + ' ' + contact.lastName}/>
                    </div>,
                    type: 'checkbox'
                });
            }
            return acc;
        }, {map: new Map(), result: []})
        .result : [];

    const users = usersData?.map((user) => ({
        key: `user${user.userId}`,
        label: user.firstName + ' ' + user.lastName,
        value: user,
        prefixElement: <div className='mx-2'><Avatar size="xs" name={user.firstName + ' ' + user.lastName}
                                                     type='initials' color={user.iconColor ?? 'auto'}/></div>,
    }));

    const groups = groupsData?.groups ? groupsData?.groups?.map((el: any) => ({
        key: `group${el.group.groupId}`,
        label: el.group.name,
        value: el.group,
        prefixElement: <div className='mx-2'><Avatar size="xs" type='icon' color={el.group.iconColor ?? 'auto'}
                                                     icon={el.group.icon ?? 'home'}/></div>,
        type: 'checkbox'
    })) : [];
    const mapToGroup = (items: any, callback: Function) => {
        return [{
            items: items,
            callback: callback,
            type: 'checkbox'
        }];
    };

    const handleContactSelect = (value: ContactSelectItem) => {
        const exists = contactsFilter.some((item: Contact) => item?.contactId === value.contactId);
        const newContactsFilter = exists
            ? contactsFilter.filter((item: Contact) => item.contactId !== value.contactId)
            : [...contactsFilter, value];

        dispatch(setContactsFilter(newContactsFilter));
    };

    const handleUserAndGroupSelect = (value: any) => {

        const exists = usersAndGroupsFilter.some((item: any) => {
            const userIdMatches = item?.userId && value?.userId && item.userId === value.userId;
            const groupIdMatches = item?.groupId && value?.groupId && item.groupId === value.groupId;
            return userIdMatches || groupIdMatches;
        });

        const newUsersAndGroupsFilter = exists
            ? usersAndGroupsFilter.filter((item: any) => item.userId != value?.userId || item?.groupId != value?.groupId)
            : [...usersAndGroupsFilter, value];

        dispatch(setUsersAndGroupsFilter(newUsersAndGroupsFilter));
    }

    const handlePhoneNumberSelect = (value: MsisdnSelectItem) => {

        const exists = phoneNumbersFilter.some((item: any) => item.msisdnId === value.msisdnId);
        const newPhoneNumbersFilter = exists
            ? phoneNumbersFilter.filter((item: any) => item.msisdnId !== value.msisdnId)
            : [...phoneNumbersFilter, value];
        dispatch(setPhoneNumbersFilter(newPhoneNumbersFilter));
    }

    const handleDateSelect = (value: any) => {
        const from = new Date(value[0].valueOf()).toISOString();
        const to = new Date(value[1].valueOf()).toISOString();
        dispatch(setSelectedDates({from, to}));
    }

    const handleBottomReach = () => {
        if (recentCallsCount > limit) {
            dispatch(setLimit(limit + 50));
        }
    }

    const tabs = [
        {
            id: '1',
            label: intl.formatMessage({id: 'calls.recent'}),
            content: groupedCalls ? <RecentCalls reachedEnd={handleBottomReach} groupedCalls={groupedCalls ?? []}/> :
                <div>loading...</div>
        },
        {
            id: '2',
            label: intl.formatMessage({id: 'calls.inboundQueue.name'}),
            content: <InboundCallQueue isMobile={isMobile} callQueue={inboundQueueCalls ?? []}/>
        },
        {
            id: '3',
            label: intl.formatMessage({id: 'calls.missed'}),
            content: <MissedCalls groupedCalls={missedCalls ?? {}}/>
        },
    ];

    const dropdownGroupPhoneNumbers = mapToGroup(phoneNumbers, handlePhoneNumberSelect);
    const dropdownGroupContacts = mapToGroup(contacts, handleContactSelect);
    const dropdownGroupUsersAndGroups = [
        {
            title: 'Users',
            items: users ?? [],
            callback: handleUserAndGroupSelect,
            type: 'checkbox'
        },
        {
            title: 'Groups',
            items: groups ?? [],
            callback: handleUserAndGroupSelect,
            type: 'checkbox'
        }
    ];
    if (isLoading) {
        return (
            <div className={`flex w-full  ${OpenDialerMargin}`}>
                <Loader/>
            </div>
        );
    }
    return (
        <>
            {!isLoading && (
                <div className='w-full'>
                    <Header header='Calls' icon={'1F919'}/>
                    <div className={`${isMobile ? ' px-[16px]' : OpenDialerMargin + ' m-8 px-0'}`}>
                        <div className={`mt-4 mb-6 flex items-start ${isMobile ? ' flex-wrap' : ' gap-4 flex-nowrap'}`}>
                            <Datepicker onDateConfirm={handleDateSelect}/>
                            <ChDropdown trigger={phoneNumbersFilterButton}
                                        type='search'
                                        onPhraseChange={(value: string) => setPhonePhrase(value)}
                                        dropdownGroup={dropdownGroupPhoneNumbers ?? []}
                                        selected={phoneNumbersFilter}></ChDropdown>
                            <ChDropdown trigger={contactsFilterButton}
                                        type='search'
                                        onPhraseChange={(value: string) => setContactPhrase(value)}
                                        dropdownGroup={dropdownGroupContacts ?? []}
                                        selected={contactsFilter}></ChDropdown>
                            <ChDropdown trigger={usersAndGroupsFilterButton}
                                        type='search'
                                        onPhraseChange={(value: string) => {
                                            setUserGroupPhrase(value)
                                        }}
                                        dropdownGroup={dropdownGroupUsersAndGroups ?? []}
                                        selected={usersAndGroupsFilter}></ChDropdown>
                            <Button size='small' label='Clear filters' buttonType='textSecondary' rightIcon='close'
                                    onClick={() => {
                                        dispatch(setPhoneNumbersFilter([]));
                                        dispatch(setContactsFilter([]));
                                        dispatch(setUsersAndGroupsFilter([]));
                                        dispatch(setSelectedDates({from: '', to: ''}));
                                    }}/>
                        </div>
                        <ChTabs headerItems={<OnGoingCalls calls={ongoingCalls} isMobile={isMobile}/>}
                                activeTabId={tabs[0].id} tabs={tabs}/>
                    </div>
                </div>
            )}
        </>
    );
}
